import { useEthers } from "./useEthers";
import { CurrencyValue, Token } from "../model";
import { DEFAULT_CHAIN_ID } from "../constants";
import { useChainCall } from "./useChainCalls";
import { Interface } from "@ethersproject/abi";
import { BigNumber } from "@ethersproject/bignumber";
import ierc20 from "../abi/erc20.abi.json";
import { useMemo } from "react";

const allowanceInterface = new Interface(ierc20);

export function useTokenAllowance(token: Token, spender: string) {
  const { chainId = DEFAULT_CHAIN_ID, account } = useEthers();

  try {
    const data = useChainCall(
      !!account &&
        token.chainId === chainId && {
          address: token.address,
          data: allowanceInterface.encodeFunctionData("allowance", [
            account.address,
            spender,
          ]),
        }
    );

    return useMemo(
      () =>
        data !== undefined
          ? new CurrencyValue(token, BigNumber.from(data))
          : undefined,
      [data]
    );
  } catch (e) {
    return new CurrencyValue(token, BigNumber.from("0"));
  }
}
