import React, { useEffect, useState } from "react";
import { Transaction, utils } from "ethers";
import styled from "styled-components";
import URUSCoin1x from "../../assets/icons/urus-coin@1x.png";
import URUSCoin2x from "../../assets/icons/urus-coin@2x.png";
import { HelpIcon } from "../../components";
import { Badge } from "../../components/common/Badge";
import { ButtonPrimary } from "../../components/common/buttons/ButtonPrimary";
import { CustomSpinnerInput } from "../../components/common/CustomSpinnerInput";
import { Input } from "../../components/common/form/Input";
import { Section } from "../../components/common/Section";
import { FailMessage, WrappedConnectButton } from "../../components/common";
import {
  Colors,
  DEFAULT_CHAIN_ID,
  MAX_STAKE_DURATION,
  STAKING_MASTER_ADDRESS,
  URUS_CONTRACT,
} from "../../constants";
import {
  useContracts,
  useEthers,
  useInterestPercentage,
  useNaturalNumberInput,
  useNumberInput,
  useUrusBalance,
} from "../../hooks";
import { useUrusAllowance } from "../../hooks/useUrusAllowance";
import {
  approve,
  CurrentTransaction,
  stake,
  TransactionStatus,
} from "../../infrastructure";
import { CurrencyValue } from "../../model";
import { isSufficientBalance, toPercent } from "../../helpers";
import { isStakingEnabled } from "./helpers";
import { TransactionInfo } from "../../components/common/TransactionInfo";

type StakeModalProps = {
  closeOnSuccess: () => void;
};

export function StakeModal({ closeOnSuccess }: StakeModalProps) {
  const { account, chainId = DEFAULT_CHAIN_ID } = useEthers();
  const urusBalance = useUrusBalance();
  const [amount, setAmount] = useNumberInput(18);
  const [status, setStatus] = useState(TransactionStatus.New);
  const [stakeTime, setStakeTime] = useNaturalNumberInput(MAX_STAKE_DURATION);
  const [currentTransaction, setCurrentTransaction] =
    useState<CurrentTransaction>();
  const { auroxToken, stakingMaster } = useContracts();
  const allowance =
    useUrusAllowance(STAKING_MASTER_ADDRESS[chainId]) ||
    CurrencyValue.fromString(URUS_CONTRACT[chainId], "0");
  const [amountValue, setAmountValue] = useState(
    CurrencyValue.fromString(URUS_CONTRACT[chainId], "0")
  );

  useEffect(
    () =>
      setAmountValue(CurrencyValue.fromString(URUS_CONTRACT[chainId], amount)),
    [amount]
  );
  useEffect(
    () =>
      currentTransaction === CurrentTransaction.StakeUrus &&
      status === TransactionStatus.Success
        ? closeOnSuccess()
        : undefined,
    [status]
  );
  const isMining = status === TransactionStatus.Mining;
  const apy = useInterestPercentage(stakeTime);
  const [transaction, setTransaction] = useState<Transaction>();

  let amountOfRewards: string | null = null;

  if (apy && amountValue) {
    const years = Number(stakeTime) / 12;
    const tokensPerYear = amountValue.value.mul(apy);

    amountOfRewards = (Math.round(Number(utils.formatEther(tokensPerYear.div(utils.parseEther("1")))) * years * 100)/100).toString();
  }

  return (
    <form>
      <Section>
        <InputLabels>
          <Label>Input</Label>
          <Label>Balance: {urusBalance?.formatWithSymbol()}</Label>
        </InputLabels>
        <InputFields>
          <Input
            id="urus-input"
            type="text"
            value={amount || "0"}
            onChange={(e) => setAmount(e.target.value)}
          />
          <Token>
            <img src={URUSCoin1x} alt="URUS coin" srcSet={URUSCoin2x}  />
            URUS
          </Token>
        </InputFields>
      </Section>
      <MonthInput>
        <CustomSpinnerInput value={stakeTime} set={setStakeTime} />
        <MonthLabel>months</MonthLabel>
      </MonthInput>
      <Hint>
        <HelpIcon />
        <HintText>You can stake from 1 month to 7 years</HintText>
      </Hint>
      <InfoBox>
        <StyledBadge>{amountOfRewards ?? 0} URUS</StyledBadge>
        The amount of rewards you receive is calculated by multiplying (months staked * amount of tokens staked *  0.5) where months staked is less than 40. You will still continue to earn the same amount of rewards overall but the amount per year will not increase for staking more than 40 months. Please note that cancelling the stake early will penalize you. Refer to our documentation:{" "}
        <InfoLink
          href="https://docs.getaurox.com/product-docs/aurox-token-guides/token-staking-information"
          target="_blank"
        >
          Click Here
        </InfoLink>
      </InfoBox>
      {account ? (
        <ButtonPrimary
          isFullWidth
          disabled={
            !isStakingEnabled(amountValue, stakeTime, status, urusBalance)
          }
          isLoading={isMining}
          onClick={
            allowance?.lt(amountValue)
              ? () =>
                  approve(
                    auroxToken,
                    stakingMaster.address,
                    amount,
                    setStatus,
                    setCurrentTransaction,
                    setTransaction
                  )
              : () =>
                  stake(
                    stakingMaster,
                    amount,
                    stakeTime,
                    account.address,
                    setStatus,
                    setCurrentTransaction,
                    setTransaction
                  )
          }
          type="button"
        >
          {isSufficientBalance(urusBalance, amountValue)
            ? allowance?.lt(amountValue)
              ? isMining
                ? "Awaiting approval"
                : `Approve ${amount} URUS`
              : isMining
              ? "Processing transaction"
              : "Stake"
            : "Insufficient balance"}
        </ButtonPrimary>
      ) : (
        <WrappedConnectButton />
      )}
      {!!transaction && status === TransactionStatus.Mining && (
        <TransactionInfo transaction={transaction} />
      )}
      {status === TransactionStatus.Fail && <FailMessage />}
    </form>
  );
}

const InputLabels = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.PrimaryTextColor};
`;

const InputFields = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
`;

const MonthInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin-top: 0;
`;

const MonthLabel = styled.p`
  font-size: 16px;
  line-height: 18px;
  margin-left: 10px;
  margin-top: 10px;
  color: ${Colors.PrimaryTextColor};
`;

const Token = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: right;
  color: ${Colors.White};
  text-transform: uppercase;

  img {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
`;

const Hint = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 54px;
`;

const HintText = styled.p`
  margin-left: 8px;
  font-size: 12px;
  line-height: 14px;
  color: ${Colors.PrimaryTextColor};
`;

const InfoBox = styled(Section)`
  position: relative;
  margin-bottom: 32px;
  padding: 32px 32px 16px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${Colors.White};
`;

const StyledBadge = styled(Badge)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 8px 28px;
`;

const InfoLink = styled.a`
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.Blue};
`;
