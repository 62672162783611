import React from "react";
import { formatRemainingTime } from "../../helpers";
import { useSecondsToEpochEnd } from "../../hooks/useSecondsToEpochEnd";
import { WarningSection, WarningText } from "../common";
import { SectionLabelDanger } from "../common/Section";

export function PenaltyWarning() {
  const seconds = useSecondsToEpochEnd();

  return (
    <WarningSection>
      <SectionLabelDanger>Warning! Penalty information</SectionLabelDanger>
      <WarningText>
        10% of the amount entered will be burnt. You can receive the amount
        specified in full on the first day of given epoch.
      </WarningText>
      <WarningText>
        Time until the start of next epoch:{" "}
        {seconds ? formatRemainingTime(seconds.toNumber()) : "?"}
      </WarningText>
    </WarningSection>
  );
}
