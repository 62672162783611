import { utils } from "ethers";
import { useMemo } from "react";
import { DECIMAL_BASE } from "../constants";
import { useLPTokenBalance } from "./useLPTokenBalance";
import { useLPTokenSupply } from "./useLPTokenSupply";

export function useShareOfPool() {
  const lpBalance = useLPTokenBalance();
  const lpTotalSupply = useLPTokenSupply();
  return useMemo(
    () =>
      lpBalance !== undefined && lpTotalSupply !== undefined
        ? utils.formatEther(
            lpBalance.value.mul(DECIMAL_BASE.pow(20)).div(lpTotalSupply.value)
          )
        : undefined,
    [lpBalance, lpTotalSupply]
  );
}
