import React from "react";
import { useRewardAmount } from "../../hooks/useRewardAmount";
import { Stake } from "../../model/Stake";

type RewardProps = {
  stake: Stake;
};

export function RewardAmount({ stake }: RewardProps) {
  const reward = useRewardAmount(stake);
  return <b>{reward ? reward.format() : undefined}</b>;
}
