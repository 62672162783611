import React from "react";
import { TextRow } from "../../components/common/text/TextRow";
import { usePoolRate } from "../../hooks";
import { useLiquidityDepositTotal } from "../../hooks/useLiquidityDepositTotal";

export function PoolStats() {
  const depositedTotal = useLiquidityDepositTotal();
  const poolRate = usePoolRate();

  return (
    <>
      <TextRow
        label="Total deposited"
        value={depositedTotal?.formatWithSymbol() || "0"}
      />
      <TextRow
        label="Pool rewards"
        value={poolRate && `${poolRate.formatWithSymbol()} / week`}
      />
    </>
  );
}
