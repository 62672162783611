import { utils } from "ethers";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { SymbolsPair } from "../common/Symbols";
import { DEFAULT_CHAIN_ID, URUS_CONTRACT } from "../../constants";
import { isSufficientBalance } from "../../helpers";
import {
  useContracts,
  useEthers,
  useFirstDayOfEpoch,
  useLiquidityDepositTotal,
  useNumberInput,
} from "../../hooks";
import { TransactionStatus, withdraw } from "../../infrastructure";
import { CurrencyValue } from "../../model";
import { FailMessage } from "../common";
import { BadgeMax } from "../common/Badge";
import { ButtonPrimary } from "../common/buttons/ButtonPrimary";
import { Input } from "../common/form/Input";
import { Modal } from "../common/modal/Modal";
import { Row } from "../common/Row";
import { Section, SectionLabel, SectionRow } from "../common/Section";
import { PenaltyWarning } from "./PenaltyWarning";

interface WithdrawModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export const WithdrawModal = ({ isVisible, onClose }: WithdrawModalProps) => {
  const { chainId = DEFAULT_CHAIN_ID } = useEthers();
  const [amount, setAmount] = useNumberInput(18);
  const value = useMemo(
    () =>
      amount
        ? new CurrencyValue(URUS_CONTRACT[chainId], utils.parseEther(amount))
        : undefined,
    [amount]
  );
  const totalDeposited = useLiquidityDepositTotal();
  const [status, setStatus] = useState(TransactionStatus.New);
  const isMining = useMemo(() => status === TransactionStatus.Mining, [status]);
  const { provider } = useContracts();
  const isFirstDayOfEpoch = useFirstDayOfEpoch();
  useEffect(
    () => (status === TransactionStatus.Success ? onClose() : undefined),
    [status]
  );

  return (
    <Modal isVisible={isVisible} onClose={onClose} title="Withdrawal">
      <TotalDeposited>
        Total deposited: <span>{totalDeposited?.formatWithSymbol()}</span>
      </TotalDeposited>
      <Section>
        <Row>
          <SectionRow>
            <SectionLabel>Input </SectionLabel>
            <BadgeMax
              onClick={
                totalDeposited
                  ? () => setAmount(totalDeposited?.toString())
                  : undefined
              }
            >
              MAX
            </BadgeMax>
          </SectionRow>
        </Row>
        <Row>
          <Input
            type="text"
            value={amount || 0}
            onChange={(e) => setAmount(e.target.value)}
          />
          <SymbolsPair chainId={chainId} />
        </Row>
      </Section>
      {!isFirstDayOfEpoch ? <PenaltyWarning /> : <Margin />}
      <ButtonPrimary
        isFullWidth
        isLoading={isMining}
        disabled={
          !Number.parseFloat(amount) ||
          isMining ||
          !isSufficientBalance(totalDeposited, value)
        }
        onClick={() => withdraw(provider, amount, setStatus)}
      >
        {isMining ? "Transaction in progress..." : "Withdraw"}
      </ButtonPrimary>
      {status === TransactionStatus.Fail && <FailMessage />}
    </Modal>
  );
};

const TotalDeposited = styled.p`
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #aab4cf;

  span {
    color: #fff;
  }
`;

const Margin = styled(Row)`
  margin: 24px 0 30px;
`;
