import { Interface } from "ethers/lib/utils";
import { useMemo } from "react";
import Provider from "../abi/Provider.json";
import {
  DEFAULT_CHAIN_ID,
  PROVIDER_ADDRESS,
  URUS_CONTRACT,
} from "../constants";
import { CurrencyValue } from "../model";
import { useChainCall } from "./useChainCalls";
import { useEthers } from "./useEthers";

const providerInterface = new Interface(Provider.abi);
const functionName = "returnAllClaimableRewardAmounts";

export function useClaimableLiquidityReward() {
  const { chainId = DEFAULT_CHAIN_ID, account } = useEthers();
  const data = useChainCall(
    !!account && {
      address: PROVIDER_ADDRESS[chainId],
      data: providerInterface.encodeFunctionData(functionName, [
        account.address,
      ]),
    }
  );
  return useMemo(() => {
    if (data !== undefined) {
      const [rewardTotal] = providerInterface.decodeFunctionResult(
        functionName,
        data
      );
      return new CurrencyValue(URUS_CONTRACT[chainId], rewardTotal);
    }
    return undefined;
  }, [data]);
}
