import React from "react";
import { Route, Switch } from "react-router-dom";
import { Page } from "../../components";
import { HomeWidgets } from "../../components/page";
import { LiquidityMining } from "../LiquidityMining";
import { Staking } from "../Staking";

export function HomePage() {
  return (
    <Page>
      <Switch>
        <Route path="/home">
          <HomeWidgets />
        </Route>
        <Route path="/staking">
          <Staking />
        </Route>
        <Route path="/mining">
          <LiquidityMining />
        </Route>
      </Switch>
    </Page>
  );
}
