import { BigNumber, BigNumberish, FixedNumber } from "ethers";

export class Fixed {
  decimals: BigNumber;

  value: BigNumber;

  constructor(_value: BigNumberish, _decimals: BigNumberish) {
    this.value = BigNumber.from(_value);
    this.decimals = BigNumber.from(_decimals);
  }

  isZero() {
    return this.value.isZero();
  }

  toString() {
    return FixedNumber.fromValue(this.value, this.decimals).toString();
  }

  format(scale: number) {
    const divisor = BigNumber.from(10).pow(this.decimals);
    const integer = this.value.div(divisor);
    const fractional = this.value.mod(divisor);
    let suffix = "";
    if (scale !== 0) {
      suffix = `.${fractional
        .toString()
        .padStart(this.decimals.toNumber(), "0")
        .padEnd(scale, "0")
        .slice(0, scale)}`;
    }
    return `${integer}${suffix}`;
  }

  static from(value: BigNumberish, decimal: BigNumberish) {
    return new Fixed(BigNumber.from(value), decimal);
  }

  static promoteDecimals(fixed: Fixed, decimals: BigNumberish) {
    if (fixed.decimals.eq(decimals)) {
      return fixed;
    }
    if (fixed.decimals.gt(decimals)) {
      throw `Can't promote Fixed number from ${fixed.decimals.toString()} to ${decimals.toString()} decimals`;
    }
    const multiplier = BigNumber.from(10).pow(
      BigNumber.from(decimals).sub(fixed.decimals)
    );
    return new Fixed(fixed.value.mul(multiplier), decimals);
  }

  static alignDecimals(lhs: Fixed, rhs: Fixed) {
    if (lhs.decimals.gt(rhs.decimals)) {
      return [lhs, Fixed.promoteDecimals(rhs, lhs.decimals)];
    }
    if (lhs.decimals.lt(rhs.decimals)) {
      return [Fixed.promoteDecimals(lhs, rhs.decimals), rhs];
    }
    return [lhs, rhs];
  }
}
