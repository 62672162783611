import styled from "styled-components";
import { Colors } from "../../../constants";

export const Input = styled.input`
  max-width: 170px;
  padding: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #fff;
  border: none;
  background: none;
  outline: none;

  &[value="0"] {
    color: ${Colors.DisabledColor};
  }
`;
