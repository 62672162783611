import React, { ReactNode } from "react";
import styled from "styled-components";

interface ModalOverlayProps {
  onClick: () => void;
  children: ReactNode;
}

export const ModalOverlay = ({ onClick, children }: ModalOverlayProps) => {
  function onBackgroundClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (e.target === e.currentTarget) {
      onClick();
    }
  }

  return <Overlay onClick={onBackgroundClick}>{children}</Overlay>;
};

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 100000;
  overflow-y: auto;
`;
