import styled from "styled-components";
import { Colors } from "../../constants";

export const Badge = styled.div`
  display: inline-block;
  padding: 8px;
  background: linear-gradient(0deg, rgba(7, 14, 29, 0.8), rgba(7, 14, 29, 0.8)),
    ${Colors.LightGreen};
  border-radius: 8px;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: ${Colors.LightGreen};
`;

export const BadgeSmall = styled.div`
  padding: 4px 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #78a8f8;
  background: rgba(36, 125, 255, 0.15);
  border: 1px solid rgba(120, 168, 248, 0.15);
  border-radius: 12px;
`;

export const BadgeMax = styled(BadgeSmall)`
  margin-left: 8px;
`;
