import { UnsupportedChainIdError } from "@web3-react/core";
import { ethers } from "ethers";
import { createContext } from "react";
import { ChainId, DEFAULT_CHAIN_ID } from "../../constants";

export interface Account {
  address: string;
}

interface IEthersContext {
  connectWallet: () => Promise<void>;
  disconnectWallet: () => void;
  account: Account | undefined;
  chainId: ChainId;
  changeChain: (chainId: ChainId) => void;
  ethersProvider: ethers.providers.Web3Provider | undefined;
  error: UnsupportedChainIdError | undefined;
}

export const EthersContext = createContext<IEthersContext>({
  connectWallet: async () => {},
  disconnectWallet: () => {},
  account: undefined,
  chainId: DEFAULT_CHAIN_ID,
  changeChain: () => {},
  ethersProvider: undefined,
  error: undefined,
});
