import React, { ReactNode, useCallback, useEffect } from "react";
import styled from "styled-components";
import { Colors } from "../../../constants";
import { BackIcon } from "../../icons";
import { Row } from "../Row";
import { Tooltip, TooltipProps } from "../Tooltip";
import { ModalOverlay } from "./ModalOverlay";
import { ModalTitle } from "./ModalTitle";

interface Props extends TooltipProps {
  onClose: () => void;
  title: string;
  children: ReactNode;
  isVisible: boolean;
  hint?: string;
}

export function Modal({
  isVisible,
  onClose,
  title,
  children,
  hint,
  tooltip,
}: Props) {
  const listenKeyboard = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (isVisible) {
      window.addEventListener("keydown", listenKeyboard, true);
      return () => {
        window.removeEventListener("keydown", listenKeyboard, true);
      };
    }
  }, [isVisible, listenKeyboard]);

  if (isVisible) {
    return (
      <ModalOverlay onClick={onClose}>
        <ModalWrapper>
          <ModalBody>
            <Row>
              <BackButton onClick={onClose}>
                <BackIcon />
              </BackButton>
              <ModalTitle>{title}</ModalTitle>
              <Tooltip tooltip={tooltip} />
            </Row>
            <ModalContent>{children}</ModalContent>
            {hint && <Hint>{hint}</Hint>}
          </ModalBody>
        </ModalWrapper>
      </ModalOverlay>
    );
  }
  return null;
}

const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 30px;
  max-width: 420px;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 15px;
`;

const ModalBody = styled.div`
  width: 100%;
  padding: 32px 16px 24px;
  background: ${Colors.BackgroundPrimary};
  border-radius: 12px;
`;

export const Hint = styled.div`
  width: 100%;
  margin-top: 16px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${Colors.White};
`;

const ModalContent = styled.div`
  margin-top: 24px;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  outline: none;
`;
