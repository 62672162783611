import cloneClass from "../helpers/cloneClass";
import { Currency, Token } from "../model";
import { ChainId } from "./chainId";

export const Ether = new Currency(ChainId.Mainnet, "Ether", "ETH", 18);
export const BNB = new Currency(ChainId.Binance, "BNB", "BNB", 18);
export const GoerliEther = new Currency(
  ChainId.Goerli,
  "Goerli Ether",
  "GETH",
  18
);

// CurrentPrice * 150 * 52 -> Value released per year
// Number of LP tokens in Provider contract
// Value released per year / Value of 1 LP token * num tokens
// APY = return / investment

export const NATIVE_CURRENCY = {
  [ChainId.Mainnet]: Ether,
  [ChainId.Goerli]: GoerliEther,
  [ChainId.Hardhat]: Ether,
  [ChainId.Binance]: BNB,
};

const createAuroxTokenInstance = (network: ChainId, address: string) =>
  new Token(network, address, "Aurox Token", "URUS", 18);

export const Urus = createAuroxTokenInstance(
  ChainId.Mainnet,
  "0xc6dddb5bc6e61e0841c54f3e723ae1f3a807260b"
);

export const GoerliUrus = createAuroxTokenInstance(
  ChainId.Goerli,
  "0xedd2ce410b0ca704c2351c66382e475cb4e5fc10"
);

export const HardhatUrus = createAuroxTokenInstance(
  ChainId.Hardhat,
  "0xc6dddb5bc6e61e0841c54f3e723ae1f3a807260b"
);

export const BinanceUrus = createAuroxTokenInstance(
  ChainId.Binance,
  "0xc6dddb5bc6e61e0841c54f3e723ae1f3a807260b"
);

export const URUS_CONTRACT = {
  [ChainId.Mainnet]: Urus,
  [ChainId.Goerli]: GoerliUrus,
  [ChainId.Hardhat]: HardhatUrus,
  [ChainId.Binance]: BinanceUrus,
};

const createWrappedEthTokenInstance = (network: ChainId, address: string) =>
  new Token(network, address, `Wrapped ${NATIVE_CURRENCY[network].name}`, `W${NATIVE_CURRENCY[network].symbol}`, 18);

export const WrappedEth = createWrappedEthTokenInstance(
  ChainId.Mainnet,
  "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
);

export const GoerliWrappedEth = createWrappedEthTokenInstance(
  ChainId.Goerli,
  "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6"
);

export const HardhatWrappedEth = createWrappedEthTokenInstance(
  ChainId.Hardhat,
  "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
);

export const BinanceWrappedEth = createWrappedEthTokenInstance(
  ChainId.Binance,
  "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
);

export const WRAPPED_NATIVE_CURRENCY = {
  [ChainId.Mainnet]: WrappedEth,
  [ChainId.Goerli]: GoerliWrappedEth,
  [ChainId.Hardhat]: HardhatWrappedEth,
  [ChainId.Binance]: BinanceWrappedEth,
};

const createLPTokenInstance = (network: ChainId, address: string) =>
  new Token(
    network,
    address,
    `URUS-${WRAPPED_NATIVE_CURRENCY[network].symbol} Liquidity Provider Token`,
    `URUS-${WRAPPED_NATIVE_CURRENCY[network].symbol} LP`,
    18
  );

export const LPToken = createLPTokenInstance(
  ChainId.Mainnet,
  "0xebD54ad6c1D4B079Bdc20Ecf36dd29d1D76c9977"
);

export const GoerliLPToken = createLPTokenInstance(
  ChainId.Goerli,
  "0xd727e20ca57b0d41fad2f7909c6898ba1794ee0c"
);

export const HardhatLPToken = createLPTokenInstance(
  ChainId.Hardhat,
  "0xebD54ad6c1D4B079Bdc20Ecf36dd29d1D76c9977"
);

export const BinanceLPToken = createLPTokenInstance(
  ChainId.Binance,
  "0x6a78caed4c4bf0ee494dd80034935323512e794a"
);

export const URUS_LP_TOKEN = {
  [ChainId.Mainnet]: LPToken,
  [ChainId.Goerli]: GoerliLPToken,
  [ChainId.Hardhat]: HardhatLPToken,
  [ChainId.Binance]: BinanceLPToken,
};
