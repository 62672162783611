import { BigNumber } from "ethers";
import { Interface } from "ethers/lib/utils";
import { useMemo } from "react";
import StakingMaster from "../abi/StakingMaster.json";
import { DEFAULT_CHAIN_ID, STAKING_MASTER_ADDRESS } from "../constants";
import { useChainCall } from "./useChainCalls";
import { useCurrentEpoch } from "./useCurrentEpoch";
import { useEthers } from "./useEthers";

const stakesInterface = new Interface(StakingMaster.abi);
const functionName = "returnInterestPercentage";

export function useInterestPercentage(duration: string, fromProvider = false) {
  const { chainId = DEFAULT_CHAIN_ID } = useEthers();
  const epoch = useCurrentEpoch();
  const data = useChainCall(
    epoch && {
      address: STAKING_MASTER_ADDRESS[chainId],
      data: stakesInterface.encodeFunctionData(functionName, [
        BigNumber.from(duration),
        epoch.eq(1) && fromProvider,
        fromProvider,
      ]),
    }
  );
  return useMemo(() => (data ? BigNumber.from(data) : undefined), [data]);
}
