import { Contract, Transaction, utils } from "ethers";
import { CurrentTransaction } from "./CurrentTransaction";
import { TransactionStatus } from "./TransactionStatus";

export const deposit = async (
  provider: Contract,
  amount: string,
  setStatus: (value: TransactionStatus) => void,
  setCurrentTransaction: (value: CurrentTransaction) => void,
  setTransaction?: (value: Transaction) => void
) => {
  try {
    setStatus(TransactionStatus.Mining);
    setCurrentTransaction(CurrentTransaction.Deposit);
    const transaction = await provider.addLiquidity(utils.parseEther(amount));
    setTransaction && setTransaction(transaction);
    const receipt = await transaction.wait();
    setStatus(
      receipt.status ? TransactionStatus.Success : TransactionStatus.Fail
    );
  } catch (exception) {
    console.error(exception);
    setStatus(TransactionStatus.Fail);
  }
};
