import styled from "styled-components";
import { Colors } from "../../constants";

export const WarningSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 56px 0 16px;
  padding: 16px;
  background: rgba(235, 87, 87, 0.2);
  border-radius: 12px;
  text-align: center;
`;

export const FailSection = styled(WarningSection)`
  margin: 8px 0 0;
`;

export const WarningText = styled.p`
  margin-top: 8px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${Colors.White};
`;
