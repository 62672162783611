import { UnsupportedChainIdError } from "@web3-react/core";
import { ethers } from "ethers";
import React, { ReactChild, useEffect, useMemo, useCallback, useState } from "react";

import {
  ChainId,
  ChainName,
  CHAIN_ID_TO_CHAIN_NAME_MAPPING,
  CHAIN_NAME_TO_CHAIN_ID_MAPPING,
} from "../../constants";

import { Account, EthersContext } from "./ethersContext";
import { getWeb3Network, getWeb3Modal, switchChain } from "./ethersHelpers";

const EthersProvider = ({ children }: { children: ReactChild }) => {
  const [web3Provider, setWeb3Provider] = useState<any>();
  const [ethersProvider, setEthersProvider] = useState<ethers.providers.Web3Provider>();
  const [account, setAccount] = useState<Account>();
  const [chainId, setChainId] = useState<ChainId>(() => CHAIN_NAME_TO_CHAIN_ID_MAPPING[getWeb3Network()] as ChainId);
  const [error, setError] = useState<UnsupportedChainIdError>();

  const web3Modal = useMemo(() => getWeb3Modal(CHAIN_ID_TO_CHAIN_NAME_MAPPING[chainId] as ChainName), [chainId]);

  useEffect(() => {
    const getAccounts = async () => {
      if (!web3Provider || !ethersProvider) {
        return;
      }

      let accounts: string[] = [];

      if (!web3Provider.bridge) {
        accounts = await ethersProvider.send("eth_requestAccounts", []);
      } else {
        accounts = web3Provider.accounts;  
      }

      setAccount({ address: accounts[0] });
    };

    getAccounts();
  }, [web3Provider, ethersProvider]);

  useEffect(() => {
    const handleAccountsChanged = (addresses: string[]) => {
      setAccount({ address: addresses[0] });
    };
    const handleChainChanged = (chainHexString: string) => {
      const _chainId = Number.parseInt(chainHexString, 16);

      if (ChainId[_chainId]) {
        setChainId(_chainId);
        setEthersProvider(new ethers.providers.Web3Provider(web3Provider))
      } else {
        disconnectWallet();
        setError(new UnsupportedChainIdError(_chainId));
      }
    };

    if (web3Provider && !web3Provider.isWalletLink) {
      web3Provider.removeAllListeners();
      web3Provider.setMaxListeners(Infinity);

      web3Provider.addListener("accountsChanged", handleAccountsChanged);
      web3Provider.addListener("chainChanged", handleChainChanged);

      return () => {
        web3Provider.removeListener("accountsChanged", handleAccountsChanged);
        web3Provider.removeListener("chainChanged", handleChainChanged);

        web3Provider.removeAllListeners();
      }
    }
  }, [web3Provider]);

  const connectWallet = useCallback(async () => {
    if (!web3Modal) {
      return;
    }

    try {
      const _web3Provider =  await switchChain(await web3Modal.connect(), CHAIN_ID_TO_CHAIN_NAME_MAPPING[chainId] as ChainName);
      
      if (!_web3Provider) {
        return;
      }

      const _ethersProvider = new ethers.providers.Web3Provider(_web3Provider);

      const { chainId: _chainId } = await _ethersProvider.getNetwork();

      if (!ChainId[_chainId]) {
        setError(new UnsupportedChainIdError(_chainId));
        return;
      }

      setWeb3Provider(_web3Provider);
      setEthersProvider(_ethersProvider);

    } catch (error) {
      console.error(error);
    }
  }, [web3Modal, chainId]);

  const disconnectWallet = useCallback(() => {
    ethersProvider?.removeAllListeners();
    setEthersProvider(undefined);
    setAccount(undefined);
    setError(undefined);
  }, []);

  const changeChain = useCallback((newChainId: ChainId) => {
    setChainId(newChainId);
    localStorage.setItem("web3network", CHAIN_ID_TO_CHAIN_NAME_MAPPING[newChainId as ChainId] ?? "");
    disconnectWallet();
  }, []);

  return (
    <EthersContext.Provider
      value={{
        connectWallet,
        disconnectWallet,
        account,
        chainId,
        changeChain,
        ethersProvider,
        error,
      }}
    >
      {children}
    </EthersContext.Provider>
  );
};

export default EthersProvider;
