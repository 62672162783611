import { Interface } from "@ethersproject/abi";
import { useEffect, useState } from "react";
import {
  DEFAULT_CHAIN_ID,
  MULTICALL_ABI,
  MULTICALL_ADDRESS,
} from "../constants";
import { useChainCall } from "./useChainCalls";
import { useEthers } from "./useEthers";

const multicallInterface = new Interface(MULTICALL_ABI);

export function useBlockTimestamp() {
  const { chainId = DEFAULT_CHAIN_ID, ethersProvider } = useEthers();
  const [currentTime, setCurrentTime] = useState<number>();

  useEffect(() => {
    ethersProvider?.on("block", async (blockNumber: number) => {
      const block = await ethersProvider.getBlock(blockNumber);
      setCurrentTime(block.timestamp);
    });
    return () => {
      ethersProvider?.off("block");
    };
  }, [ethersProvider]);

  const data = useChainCall({
    address: MULTICALL_ADDRESS[chainId],
    data: multicallInterface.encodeFunctionData("getCurrentBlockTimestamp", []),
  });

  return currentTime;
}
