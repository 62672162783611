import {
  DEFAULT_CHAIN_ID,
  MULTICALL_ABI,
  MULTICALL_ADDRESS,
  NATIVE_CURRENCY,
} from "../constants";
import { useChainCall } from "./useChainCalls";
import { Interface } from "@ethersproject/abi";
import { BigNumber } from "@ethersproject/bignumber";
import { useEthers } from "./useEthers";
import { CurrencyValue } from "../model";

const multicallInterface = new Interface(MULTICALL_ABI);

export function useEthBalance() {
  const { chainId = DEFAULT_CHAIN_ID, account } = useEthers();

  const data = useChainCall(
    !!account && {
      address: MULTICALL_ADDRESS[chainId],
      data: multicallInterface.encodeFunctionData("getEthBalance", [
        account.address,
      ]),
    }
  );

  const currency = NATIVE_CURRENCY[chainId];
  return data !== undefined
    ? new CurrencyValue(currency, BigNumber.from(data))
    : undefined;
}
