import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { HomePage } from "./pages/HomePage/HomePage";

export function App() {
  return (
    <Switch>
      <Route exact path="/home" component={HomePage} />
      <Route exact path="/mining" component={HomePage} />
      <Route exact path="/staking" component={HomePage} />
      <Redirect from="" to="/home" />
    </Switch>
  );
}
