import {
  DEFAULT_CHAIN_ID,
  PROVIDER_ADDRESS,
  URUS_LP_TOKEN,
} from "../constants";
import { useEthers } from "./useEthers";
import { useTokenAllowance } from "./useTokenAllowance";

export function useLPAllowance() {
  const { chainId = DEFAULT_CHAIN_ID } = useEthers();
  const lpToken = URUS_LP_TOKEN[chainId];
  const provider = PROVIDER_ADDRESS[chainId];
  return useTokenAllowance(lpToken, provider);
}
