import { BigNumber, BigNumberish } from "ethers";
import { Fixed } from "./Fixed";

export class Fraction {
  readonly numerator: BigNumber;

  readonly denominator: BigNumber;

  constructor(_numerator: BigNumberish, _denominator: BigNumberish) {
    this.numerator = BigNumber.from(_numerator);
    this.denominator = BigNumber.from(_denominator);
    if (this.denominator.eq(0)) {
      throw "Division by zero";
    }
  }

  static fromFixed(numerator: Fixed, denominator: Fixed) {
    const [n, d] = Fixed.alignDecimals(numerator, denominator);
    return new Fraction(n.value, d.value);
  }

  static from(
    numerator: BigNumberish,
    numeratorDecimals: BigNumberish,
    denominator: BigNumberish,
    denominatorDecimals: BigNumberish
  ) {
    const numeratorAsFixed = new Fixed(numerator, numeratorDecimals);
    const denominatorAsFixed = new Fixed(denominator, denominatorDecimals);
    return Fraction.fromFixed(numeratorAsFixed, denominatorAsFixed);
  }

  toFixed(scale: number) {
    const multiplier = BigNumber.from(10).pow(scale);
    const result = this.numerator.mul(multiplier).div(this.denominator);
    return Fixed.from(result, scale);
  }

  format(scale: number) {
    return this.toFixed(scale).format(scale);
  }

  toString() {
    return this.toFixed(18).toString();
  }

  isOne() {
    return this.numerator.eq(this.denominator);
  }

  reverse() {
    return new Fraction(this.denominator, this.numerator);
  }
}
