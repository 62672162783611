import { useState } from "react";

const NORMAL_REGEX = /^(0*)(\d+)$/;

export function useNaturalNumberInput(maxValue: number) {
  const [value, setValue] = useState("0");
  function set(value: string) {
    if (value.length === 0) {
      setValue("0");
    }
    const match = value.match(NORMAL_REGEX);
    if (match) {
      if (Number.parseInt(match[2], 10) <= maxValue) {
        setValue(match[2]);
      } else {
        setValue(maxValue.toString());
      }
    }
  }
  return [value, set] as const;
}
