import { BigNumber } from "ethers";
import { Interface } from "ethers/lib/utils";
import { useMemo } from "react";
import StakingMaster from "../abi/StakingMaster.json";
import {
  DEFAULT_CHAIN_ID,
  STAKING_MASTER_ADDRESS,
  URUS_CONTRACT,
} from "../constants";
import { CurrencyValue } from "../model";
import { Stake } from "../model/Stake";
import { useChainCall } from "./useChainCalls";
import { useEthers } from "./useEthers";

const stakesInterface = new Interface(StakingMaster.abi);
const signature = "returnStakesClaimableRewards";

export function useRewardAmount(stake: Stake) {
  const { chainId = DEFAULT_CHAIN_ID } = useEthers();
  const data = useChainCall({
    address: STAKING_MASTER_ADDRESS[chainId],
    data: stakesInterface.encodeFunctionData(signature, [stake.address]),
  });
  return useMemo(
    () =>
      data
        ? new CurrencyValue(URUS_CONTRACT[chainId], BigNumber.from(data))
        : undefined,
    [data]
  );
}
