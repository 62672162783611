import React from "react";
import styled from "styled-components";
import { ChainId, Colors, DEFAULT_CHAIN_ID, WRAPPED_NATIVE_CURRENCY } from "../../constants";
import URUSLogo1x from "../../assets/icons/urus-coin@1x.png";
import URUSLogo2x from "../../assets/icons/urus-coin@2x.png";
import ETHLogo1x from "../../assets/icons/ether-logo@1x.png";
import ETHLogo2x from "../../assets/icons/ether-logo@2x.png";
import BNBLogo1x from "../../assets/icons/bnb-logo@1x.png";
import BNBLogo2x from "../../assets/icons/bnb-logo@2x.png";

export const SymbolsPair = ({ chainId }: {chainId?: ChainId}) => (
  <SymbolsWrapper>
    <img src={URUSLogo1x} alt="URUS coin" srcSet={URUSLogo2x} />
    {chainId === ChainId.Binance ? (
      <img src={BNBLogo1x} alt="BNB coin" srcSet={BNBLogo2x} />
    ) : (
      <img src={ETHLogo1x} alt="ETH coin" srcSet={ETHLogo2x} />
    )}
    
    <Text>URUS-{WRAPPED_NATIVE_CURRENCY[chainId ?? DEFAULT_CHAIN_ID].symbol}</Text>
  </SymbolsWrapper>
);

export const BNBSymbol = () => (
  <SymbolsWrapper>
    <img src={BNBLogo1x} alt="BNB coin" srcSet={BNBLogo2x} />
    <Text>WBNB</Text>
  </SymbolsWrapper>
);

export const ETHSymbol = () => (
  <SymbolsWrapper>
    <img src={ETHLogo1x} alt="ETH coin" srcSet={ETHLogo2x} />
    <Text>WETH</Text>
  </SymbolsWrapper>
);

export const URUSSymbol = () => (
  <SymbolsWrapper>
    <img src={URUSLogo1x} alt="URUS coin" srcSet={URUSLogo2x} />
    <Text>URUS</Text>
  </SymbolsWrapper>
);

export const NativeSymbol = ({ chainId }: { chainId?: ChainId }) => chainId === ChainId.Binance ? <BNBSymbol /> : <ETHSymbol />;

const SymbolsWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    max-width: 22px;
  }
`;

const Text = styled.p`
  margin-left: 8px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${Colors.SecondaryTextColor};
  text-transform: uppercase;
`;
