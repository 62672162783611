import { DECIMAL_BASE } from "../constants";
import { useLPAmountMinted } from "./useLPAmountMinted";
import { useLPTokenSupply } from "./useLPTokenSupply";

export function useEstimatedPoolShare(amountUrus: string, amountEth: string) {
  const totalSupply = useLPTokenSupply();
  const mintedEstimate = useLPAmountMinted(amountUrus, amountEth);
  return totalSupply !== undefined && mintedEstimate !== undefined
    ? mintedEstimate.value
        .mul(DECIMAL_BASE.pow(18))
        .div(totalSupply.value.add(mintedEstimate.value))
    : undefined;
}
