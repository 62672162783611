export function formatValueString(value: string, precision: number) {
  const decimalPoint = value.indexOf(".");
  if (decimalPoint < 0) {
    return value;
  }
  if (decimalPoint >= precision) {
    return value.substring(0, decimalPoint);
  }
  return value.substring(0, precision + 1);
}
