import React, { useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import { ButtonPrimary } from "../../components/common/buttons/ButtonPrimary";
import { Input } from "../../components/common/form/Input";
import { Modal } from "../../components/common/modal/Modal";
import { Row } from "../../components/common/Row";
import { Section, SectionLabel } from "../../components/common/Section";
import { NativeSymbol, URUSSymbol } from "../../components/common/Symbols";
import { WrappedConnectButton } from "../../components/common";
import { Colors, DEFAULT_CHAIN_ID, WRAPPED_NATIVE_CURRENCY } from "../../constants";
import {
  useContracts,
  useEthBalance,
  useEthers,
  useShareOfPool,
  useTokenPrice,
  useUrusBalance,
} from "../../hooks";
import { useUrusAllowance } from "../../hooks/useUrusAllowance";
import { approve, TransactionStatus } from "../../infrastructure";
import { CurrencyValue } from "../../model";
import { ConfirmationModal } from "./ConfirmationModal";
import { areValuesZero, displayExchangeRate } from "./helpers";
import { initialState, liquidityReducer } from "./liquidityReducer";
import { formatValueString } from "../../helpers";
import { Transaction } from "ethers";
import { TransactionInfo } from "../../components/common/TransactionInfo";

interface AddLiquidityModalProps {
  onClose: () => void;
  isVisible: boolean;
}

export const AddLiquidityModal = ({
  isVisible,
  onClose,
}: AddLiquidityModalProps) => {
  const { account, chainId } = useEthers();
  const [values, dispatch] = useReducer(liquidityReducer, initialState);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState(TransactionStatus.New);
  const price = useTokenPrice();

  const prices = {
    ethPerUrus: price ? displayExchangeRate(price, "eth") : "",
    urusPerEth: price ? displayExchangeRate(price, "urus") : "",
  };
  const { auroxToken, uniswapRouter } = useContracts();
  useEffect(
    () =>
      price ? dispatch({ type: "priceChanged", value: price }) : undefined,
    [price]
  );
  const ethBalance = useEthBalance();
  const urusBalance = useUrusBalance();
  const uniswapAllowance = useUrusAllowance(uniswapRouter.address);

  const insufficientBalance =
    ethBalance?.lt(
      CurrencyValue.fromString(ethBalance.currency, values.ethAmount)
    ) ||
    urusBalance?.lt(
      CurrencyValue.fromString(urusBalance.currency, values.urusAmount)
    );

  const insufficientAllowance =
    urusBalance !== undefined &&
    uniswapAllowance !== undefined &&
    CurrencyValue.fromString(urusBalance.currency, values.urusAmount).gt(
      uniswapAllowance
    );

  const shareOfPool = useShareOfPool();

  const isMining = approvalStatus === TransactionStatus.Mining;

  const [transaction, setTransaction] = useState<Transaction>();

  return (
    <>
      <Modal
        isVisible={isVisible}
        onClose={onClose}
        title="Add Liquidity"
      >
        <Section>
          <Row>
            <SectionLabel>Input </SectionLabel>
            <SectionLabel>Balance: {urusBalance?.format()}</SectionLabel>
          </Row>
          <Row>
            <Input
              type="text"
              value={values.urusAmount}
              onChange={(e) =>
                dispatch({
                  type: "amountChanged",
                  fieldChanged: "urus",
                  value: e.target.value,
                })
              }
            />
            <URUSSymbol />
          </Row>
        </Section>
        <Plus>+</Plus>
        <Section>
          <Row>
            <SectionLabel>Input </SectionLabel>
            <SectionLabel>Balance: {ethBalance?.format()}</SectionLabel>
          </Row>
          <Row>
            <Input
              type="text"
              value={values.ethAmount}
              onChange={(e) =>
                dispatch({
                  type: "amountChanged",
                  fieldChanged: "eth",
                  value: e.target.value,
                })
              }
            />
            <NativeSymbol chainId={chainId} />
          </Row>
        </Section>
        <StyledSection>
          <SectionHeader>Prices and pool share</SectionHeader>
          <SectionBody>
            <div>
              <SectionValue>{prices.ethPerUrus}</SectionValue>
              <SectionLabel>{WRAPPED_NATIVE_CURRENCY[chainId ?? DEFAULT_CHAIN_ID].symbol} per URUS</SectionLabel>
            </div>
            <div>
              <SectionValue>{prices.urusPerEth}</SectionValue>
              <SectionLabel>URUS per {WRAPPED_NATIVE_CURRENCY[chainId ?? DEFAULT_CHAIN_ID].symbol}</SectionLabel>
            </div>
            <div>
              <SectionValue>
                {shareOfPool ? formatValueString(shareOfPool, 8) : "0"}%
              </SectionValue>
              <SectionLabel>Share of Pool</SectionLabel>
            </div>
          </SectionBody>
        </StyledSection>
        {account ? (
          <ButtonPrimary
            isFullWidth
            onClick={
              insufficientAllowance
                ? () =>
                    approve(
                      auroxToken,
                      uniswapRouter.address,
                      values.urusAmount,
                      setApprovalStatus,
                      () => null,
                      setTransaction
                    )
                : () => setIsConfirmationVisible(true)
            }
            disabled={insufficientBalance || isMining || areValuesZero(values)}
            isLoading={isMining}
          >
            {insufficientBalance
              ? "Insufficient balance"
              : insufficientAllowance
              ? "Approve URUS"
              : "Confirm Liquidity"}
          </ButtonPrimary>
        ) : (
          <WrappedConnectButton />
        )}
        {!!transaction && isMining && (
          <TransactionInfo transaction={transaction} />
        )}
      </Modal>
      {isConfirmationVisible ? (
        <ConfirmationModal
          isVisible={isConfirmationVisible}
          onClose={() => setIsConfirmationVisible(false)}
          ethAmount={values.ethAmount}
          urusAmount={values.urusAmount}
          {...prices}
        />
      ) : undefined}
    </>
  );
};

const Plus = styled.p`
  margin: 16px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: ${Colors.White};
`;

const StyledSection = styled(Section)`
  padding: 0;
  margin: 24px 0;
`;

const SectionHeader = styled.div`
  padding: 16px;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.White};
`;

const SectionBody = styled(Section)`
  display: flex;
  justify-content: space-between;
  text-align: center;
  border-left: none;
  border-bottom: none;
  border-right: none;
`;

const SectionValue = styled.p`
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.White};
`;
