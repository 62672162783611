import { Contract } from "ethers";
import { TransactionStatus } from "./TransactionStatus";

const CLAIM_LIQUIDITY_REWARD_GAS_LIMIT = 2000000;

const CLAIM_LIQUIDITY_GAS_BUFFER = 100000;

export const claimLiquidityReward = async (
  provider: Contract,
  setStatus: (value: TransactionStatus) => void
) => {
  try {
    setStatus(TransactionStatus.Mining);
    const gasEstimate = await provider.estimateGas.claimRewards(false, 0);
    const transaction = await provider.claimRewards(false, 0, {
      gasLimit: gasEstimate.add(CLAIM_LIQUIDITY_GAS_BUFFER),
    });
    const receipt = await transaction.wait();
    setStatus(
      receipt.status ? TransactionStatus.Success : TransactionStatus.Fail
    );
  } catch (error) {
    console.error(error);
    setStatus(TransactionStatus.Fail);
  }
};

export const claimAndStakeLiquidityReward = async (
  provider: Contract,
  months: string,
  setStatus: (value: TransactionStatus) => void
) => {
  try {
    setStatus(TransactionStatus.Mining);
    const transaction = await provider.claimRewards(true, months, {
      gasLimit: CLAIM_LIQUIDITY_REWARD_GAS_LIMIT,
    });
    const receipt = await transaction.wait();
    setStatus(
      receipt.status ? TransactionStatus.Success : TransactionStatus.Fail
    );
  } catch (error) {
    console.error(error);
    setStatus(TransactionStatus.Fail);
  }
};
