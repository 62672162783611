import React, { ReactNode } from "react";
import styled from "styled-components";
import { Colors } from "../../../constants";

interface ButtonPrimaryProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isFullWidth?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  children: ReactNode;
}

export const ButtonPrimary = ({
  isFullWidth,
  isLoading,
  disabled,
  children,
  ...props
}: ButtonPrimaryProps) => (
  <Button isFullWidth={isFullWidth} disabled={disabled || isLoading} {...props}>
    {isLoading && <div className="loader" />}
    {children}
  </Button>
);

export const Button = styled.button<ButtonPrimaryProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ isFullWidth }) => (isFullWidth ? "100%" : "auto")};
  padding: ${({ isFullWidth }) => (isFullWidth ? "19px 16px" : "0")};
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.White};
  background: ${Colors.Blue};
  border: none;
  border-radius: 8px;
  outline: none;

  &:disabled,
  &[disabled] {
    background: #41444e;
    color: ${Colors.DisabledColor};
    cursor: not-allowed;
  }

  &.invalid {
    color: #c68a8e;
    background: #874b4f;
    cursor: not-allowed;
  }

  .loader,
  .loader:after {
    border-radius: 50%;
    width: 16px;
    height: 16px;
  }
  .loader {
    position: relative;
    margin: 0 8px 0 0;
    font-size: 2px;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: spin 1.1s infinite linear;
    animation: spin 1.1s infinite linear;
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
