import WalletConnectProvider from "@walletconnect/web3-provider";

import { ChainId } from "./chainId";

export const PROVIDER_OPTIONS = {
  injected: {
    display: {
      name: "Injected",
      description: "Connect with the provider in your Browser",
    },
    package: null,
  },
  walletconnect: {
    display: {
      name: "Mobile",
      description: "Scan qrcode with your mobile wallet",
    },
    package: WalletConnectProvider,
    options: {
      infuraId: "8242075e0b5845699fc97575aef9385c",
    },
  },
};

function sampleValue(entries: string[]): string {
  const size = entries.length;

  return entries[Math.round(Math.random() * (size - 1))];
}

export const PROVIDER_OPTIONS_BY_CHAIN: Partial<Record<ChainId, any>> = {
  [ChainId.Mainnet]: {
    injected: PROVIDER_OPTIONS.injected,
    walletconnect: {
      ...PROVIDER_OPTIONS.walletconnect,
      options: {
        ...PROVIDER_OPTIONS.walletconnect.options,
        pollingInterval: 12e5,
      },
    },
  },
  [ChainId.Binance]: {
    injected: PROVIDER_OPTIONS.injected,
    walletconnect: {
      ...PROVIDER_OPTIONS.walletconnect,
      options: {
        ...PROVIDER_OPTIONS.walletconnect.options,
        pollingInterval: 12e5,
        network: "binance",
        rpc: {
          [ChainId.Binance]: sampleValue(["https://bsc-dataseed1.binance.org", "https://bsc-dataseed1.ninicoin.io", "https://bsc-dataseed1.defibit.io"]),
        },
      },
    },
  },
}