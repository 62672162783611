import React, { useState } from "react";
import styled from "styled-components";

import { ButtonPrimary } from "../../components/common/buttons/ButtonPrimary";
import { useEthers } from "../../hooks";
import { Colors, DEFAULT_CHAIN_ID, WRAPPED_NATIVE_CURRENCY, LIQUIDITY_INFO } from "../../constants";

import { AddLiquidityModal } from "./AddLiquidityModal";

export const AddLiquidityTokens = () => {
  const { chainId } = useEthers();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { token: liquidityToken, exchange } = LIQUIDITY_INFO[chainId ?? DEFAULT_CHAIN_ID];

  return (
    <View>
      <BoxTitle>Get {liquidityToken} Liquidity tokens</BoxTitle>
      <BoxText>
        {liquidityToken} LP tokens are required. You can add liquidity to our {exchange}{" "}
        listing using the button below or by providing the liquidity directly on{" "}
        {exchange}.
      </BoxText>
      <BoxButton onClick={() => setIsModalOpen(true)}>
        Add URUS-{WRAPPED_NATIVE_CURRENCY[chainId ?? DEFAULT_CHAIN_ID].symbol} liquidity
      </BoxButton>
      <AddLiquidityModal
        isVisible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </View>
  );
};

const View = styled.div`
  padding: 16px 24px 24px;
  background: ${Colors.BackgroundPrimary};
  border-radius: 8px;
`;

const BoxTitle = styled.p`
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.SecondaryTextColor};
`;

const BoxText = styled.p`
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.SecondaryTextColor};
`;

const BoxButton = styled(ButtonPrimary)`
  padding: 9px 16px;
`;
