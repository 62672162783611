export const Colors = {
  White: "#FFFFFF",
  Black: "#000000",
  Red: "#ff9090",
  Green: "#0ddf7d",
  LightGreen: "#89FFBA",
  Blue: "#247DFF",
  LightBlue: "#63A2FF",
  Gray: "#939393",
  GrayAlt: "#BCCBEC",
  LightGray: "#e8e8e8",
  Shadow: "0 2px 5px 0 rgba(0, 0, 0, 0.3)",
  MainBackground: "#070e1d",
  BackgroundPrimary: "#161D2D",
  BackgroundSecondary: "rgba(28, 39, 63, 0.2)",
  PrimaryTextColor: "#AAB4CF",
  SecondaryTextColor: "#f2f8ff",
  BorderPrimary: "#1e2739",
  BorderSecondary: "#272E3D",
  DisabledColor: "#66708C",
  Success: "#6FCF97",
  Danger: "#EB5757",
};

export const Fonts = {
  Body: "Roboto, Ubuntu, Arial, Helvetica, sans-serif",
  Monospace: "'Ubuntu Mono', monospace",
};
