import { Contract, Transaction, utils } from "ethers";
import { CurrentTransaction } from "./CurrentTransaction";
import { TransactionStatus } from "./TransactionStatus";

export const approve = async (
  token: Contract,
  address: string,
  value: string,
  setStatus: (value: TransactionStatus) => void,
  setCurrentTransaction?: (value: CurrentTransaction) => void,
  setTransaction?: (value: Transaction | undefined) => void
) => {
  try {
    setStatus(TransactionStatus.Mining);
    setCurrentTransaction?.(CurrentTransaction.Approve);
    const transaction = await token.approve(address, utils.parseEther(value));
    setTransaction && setTransaction(transaction);
    const receipt = await transaction.wait();
    setStatus(
      receipt.status ? TransactionStatus.Success : TransactionStatus.Fail
    );
    setTransaction && setTransaction(undefined);
  } catch (exception) {
    console.error(exception);
    setStatus(TransactionStatus.Fail);
    setTransaction && setTransaction(undefined);
  }
};
