import { ChainId } from "./chainId";

export const MULTICALL_ADDRESS = {
  [ChainId.Mainnet]: "0xeefba1e63905ef1d7acba5a8513c70307c1ce441",
  [ChainId.Goerli]: "0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e",
  [ChainId.Hardhat]: "0xeefba1e63905ef1d7acba5a8513c70307c1ce441",
  [ChainId.Binance]: "0xC50F4c1E81c873B2204D7eFf7069Ffec6Fbe136D",
};

export const STAKING_MASTER_ADDRESS = {
  [ChainId.Mainnet]: "0xf0029F64eFBf97bEc5C3c217004513D80d70eC92",
  [ChainId.Goerli]: "0x6B18651e76c28C371EB4DF6134437A5306C507bE",
  [ChainId.Hardhat]: "0xf0029F64eFBf97bEc5C3c217004513D80d70eC92",
  [ChainId.Binance]: "0xf0029F64eFBf97bEc5C3c217004513D80d70eC92",
};

export const PROVIDER_ADDRESS = {
  [ChainId.Mainnet]: "0x3311d37cd032E8c25C27C5A62AB185F9C60A00eC",
  [ChainId.Goerli]: "0x1C874086F9d733FA7fb33A3B8D3247A0c681Ea9A",
  [ChainId.Hardhat]: "0x3311d37cd032E8c25C27C5A62AB185F9C60A00eC",
  [ChainId.Binance]: "0x81b0777C3Dae9E52C10c0cCBD919B932E4245798",
};

export const UNISWAPV2ROUTER02 = {
  [ChainId.Mainnet]: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
  [ChainId.Goerli]: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
  [ChainId.Hardhat]: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
  [ChainId.Binance]: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
};

export const PROVIDER_MIGRATION_ADDRESS = {
  [ChainId.Mainnet]: "0xd300bf1c650679cc0c3be90b0134439d662b5b78",
  [ChainId.Goerli]: "0x656d825dcFEde288Eac469CaFA24456Ca6Bb07e0",
  [ChainId.Hardhat]: "0x367761085BF3C12e5DA2Df99AC6E1a824612b8fb",
  [ChainId.Binance]: "0xd300bf1c650679cc0c3be90b0134439d662b5b78",
};
