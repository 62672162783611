import React, { useState } from "react";
import styled from "styled-components";
import { useEthers } from "../../hooks";
import { Colors } from "../../constants";
import { ButtonPrimary } from "../../components/common/buttons/ButtonPrimary";
import { DepositModal } from "./DepositModal";
import { SymbolsPair } from "../../components/common/Symbols";
import { PoolStats } from "./PoolStats";

export const DepositTokens = () => {
  const { chainId } = useEthers();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <View>
      <BoxHeader>
        <SymbolsPair chainId={chainId} />
        <div>
          <DepositButton onClick={() => setIsModalOpen(true)}>
            Deposit
          </DepositButton>
        </div>

        <DepositModal
          isVisible={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </BoxHeader>
      <PoolStats />
    </View>
  );
};

const View = styled.div`
  padding: 16px 24px 24px;
  background: ${Colors.BackgroundPrimary};
  border-radius: 8px;
  margin-bottom: 24px;
`;

const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const DepositButton = styled(ButtonPrimary)`
  width: 124px;
  height: 36px;
`;
