import { Interface } from "@ethersproject/abi";
import { useEffect, useState } from "react";
import { DEFAULT_CHAIN_ID, STAKING_MASTER_ADDRESS } from "../constants";
import { useChainCall } from "./useChainCalls";
import { useEthers } from "./useEthers";
import StakingMaster from "../abi/StakingMaster.json";

const stakesInterface = new Interface(StakingMaster.abi);
const functionName = "returnUsersStakes";

export function useUsersStakes() {
  const { chainId = DEFAULT_CHAIN_ID, account } = useEthers();
  const stakingMaster = STAKING_MASTER_ADDRESS[chainId];

  const [stakes, setStakes] = useState<string[]>();

  const data = useChainCall(
    !!account && {
      address: stakingMaster,
      data: stakesInterface.encodeFunctionData(functionName, [account.address]),
    }
  );

  useEffect(() => {
    if (data !== undefined) {
      const stakesArray = stakesInterface.decodeFunctionResult(
        functionName,
        data
      );
      if (stakesArray) {
        setStakes([...stakesArray[0].values()]);
      }
    }
  }, [data]);

  return stakes;
}
