import React from "react";
import { ButtonWrapper } from ".";
import { Connection } from "..";

export function WrappedConnectButton() {
  return (
    <ButtonWrapper>
      <Connection />
    </ButtonWrapper>
  );
}
