import styled from "styled-components";
import { Colors } from "../../../constants";

export const ModalTitle = styled.h2`
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: ${Colors.White};
`;
