import React from "react";

import { utils } from "ethers";

import { Stake } from "../../model/Stake";
import { CurrencyValue } from "../../model";
import { DEFAULT_CHAIN_ID, URUS_CONTRACT } from "../../constants";
import { useEthers } from "../../hooks";

type AmountOfRewardsProps = {
  stake: Stake;
};

export function AmountOfRewards({ stake }: AmountOfRewardsProps) {
  const { chainId = DEFAULT_CHAIN_ID} = useEthers();

  const amountOfRewards = new CurrencyValue(URUS_CONTRACT[chainId], stake.rawInvestedAmount.add(stake.interestRate.mul(stake.rawInvestedAmount).div(utils.parseEther("1"))));

  return <>{amountOfRewards ? amountOfRewards.format() : null}</>;
}
