import React, { useCallback, ChangeEvent } from "react";
import styled from "styled-components";

import { CHAIN_NAMES, CHAIN_METADATA, Colors } from "../../constants";
import { useEthers } from "../../hooks";

export default function NetworkSelector() {
  const { chainId, changeChain } = useEthers();

  const handleChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    const newChainId = Number(event.target.value);

    if (chainId !== newChainId) { 
      changeChain(newChainId);
    }
  }, [chainId, changeChain]);

  return (
    <Select onChange={handleChange} defaultValue={chainId}>
      {CHAIN_NAMES.map((chainName) => {
        const chainMetadata = CHAIN_METADATA[chainName];

        if (chainMetadata) {
          const { chainId, chainFullName } = chainMetadata;

          return (
            <option key={chainId} value={chainId}>{chainFullName}</option>
          );
        }

        return null;
      })}
    </Select>
  )
}

const Select = styled.select`
  height: 36px;
  margin-left: auto;
  margin-right: 6px;
  padding: 6px 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.White};
  background: ${Colors.Blue};
  border: none;
  border-radius: 8px;
  outline: none;
`;