import { Interface } from "ethers/lib/utils";
import { DEFAULT_CHAIN_ID, STAKING_MASTER_ADDRESS } from "../constants";
import { useEthers } from "./useEthers";
import StakingMaster from "../abi/StakingMaster.json";
import { useChainCall } from "./useChainCalls";
import { useMemo } from "react";
import { BigNumber } from "ethers";

const stakesInterface = new Interface(StakingMaster.abi);
const functionName = "returnClaimAmountForEarlyStakeClose";

export function useAmountForStakeCancel(stakeAddress: string) {
  const { chainId = DEFAULT_CHAIN_ID, account } = useEthers();
  const data = useChainCall(
    !!account && {
      address: STAKING_MASTER_ADDRESS[chainId],
      data: stakesInterface.encodeFunctionData(functionName, [stakeAddress]),
    }
  );
  return useMemo(() => (data ? BigNumber.from(data) : undefined), [data]);
}
