import { Transaction } from "ethers";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FailMessage, WrappedConnectButton } from "../../components/common";
import { BadgeMax } from "../../components/common/Badge";
import { ButtonPrimary } from "../../components/common/buttons/ButtonPrimary";
import { Input } from "../../components/common/form/Input";
import { Modal } from "../../components/common/modal/Modal";
import { Row } from "../../components/common/Row";
import {
  Section,
  SectionLabel,
  SectionRow,
} from "../../components/common/Section";
import { SymbolsPair } from "../../components/common/Symbols";
import { TransactionInfo } from "../../components/common/TransactionInfo";
import { URUS_LP_TOKEN, LIQUIDITY_INFO, DEFAULT_CHAIN_ID } from "../../constants";
import { isSufficientBalance } from "../../helpers";
import {
  useContracts,
  useEthers,
  useLPTokenBalance,
  useNumberInput,
} from "../../hooks";
import { useLPAllowance } from "../../hooks/useLPAllowance";
import {
  approve,
  CurrentTransaction,
  deposit,
  TransactionStatus,
} from "../../infrastructure";
import { CurrencyValue } from "../../model";
import { PoolStats } from "./PoolStats";

interface DepositModalProps {
  onClose: () => void;
  isVisible: boolean;
}

export const DepositModal = ({ isVisible, onClose }: DepositModalProps) => {
  const { account, chainId } = useEthers();
  const [value, setValue] = useNumberInput(18);
  const lpTokenBalance = useLPTokenBalance();
  const [status, setStatus] = useState(TransactionStatus.New);
  const [currentTransaction, setCurrentTransaction] = useState<
    CurrentTransaction
  >();
  const { lpToken, provider } = useContracts();
  const allowance = useLPAllowance();
  const [currencyValue, setCurrencyValue] = useState<CurrencyValue | null>(
    null
  );
  useEffect(
    () =>
      chainId &&
      setCurrencyValue(CurrencyValue.fromString(URUS_LP_TOKEN[chainId], value)),
    [value]
  );
  const isMining = status === TransactionStatus.Mining;
  useEffect(
    () =>
      currentTransaction == CurrentTransaction.Deposit &&
      status == TransactionStatus.Success
        ? onClose()
        : undefined,
    [status]
  );
  const [transaction, setTransaction] = useState<Transaction>();

  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      title="Liquidity mining"
    >
      <Section>
        <Row>
          <SectionRow>
            <SectionLabel>Input </SectionLabel>
            <BadgeMax
              onClick={() => setValue(lpTokenBalance?.toString() || "")}
            >
              MAX
            </BadgeMax>
          </SectionRow>
          <SectionLabel>Balance: {lpTokenBalance?.format()}</SectionLabel>
        </Row>
        <Row>
          <Input
            type="text"
            value={value || 0}
            onChange={(e) => setValue(e.target.value)}
          />
          <SymbolsPair chainId={chainId} />
        </Row>
      </Section>
      <DepositData>
        <PoolStats />
      </DepositData>
      {account ? (
        <DepositButton
          isFullWidth
          disabled={
            !Number.parseFloat(value) ||
            isMining ||
            (!!currencyValue &&
              !isSufficientBalance(lpTokenBalance, currencyValue))
          }
          onClick={
            currencyValue && allowance?.lt(currencyValue)
              ? () =>
                  approve(
                    lpToken,
                    provider.address,
                    value,
                    setStatus,
                    setCurrentTransaction,
                    setTransaction
                  )
              : () =>
                  deposit(
                    provider,
                    value,
                    setStatus,
                    setCurrentTransaction,
                    setTransaction
                  )
          }
          type="button"
          isLoading={isMining}
        >
          {currencyValue && isSufficientBalance(lpTokenBalance, currencyValue)
            ? allowance?.lt(currencyValue)
              ? isMining
                ? "Awaiting approval"
                : `Approve ${value} URUS-LP`
              : isMining
              ? "Processing transaction"
              : `Deposit ${LIQUIDITY_INFO[chainId ?? DEFAULT_CHAIN_ID].token} LP Tokens`
            : "Insufficient balance"}
        </DepositButton>
      ) : (
        <WrappedConnectButton />
      )}
      {!!transaction && status === TransactionStatus.Mining && (
        <TransactionInfo transaction={transaction} />
      )}
      {status === TransactionStatus.Fail && <FailMessage />}
    </Modal>
  );
};

const DepositData = styled.div`
  margin-top: 30px;
`;

const DepositButton = styled(ButtonPrimary)`
  margin-top: 51px;
`;
