import { BigNumber } from "ethers";
import { Interface } from "ethers/lib/utils";
import { useMemo } from "react";
import { useEthers } from ".";
import StakingMaster from "../abi/StakingMaster.json";
import { DEFAULT_CHAIN_ID, STAKING_MASTER_ADDRESS } from "../constants";
import { useChainCall } from "./useChainCalls";

const stakesInterface = new Interface(StakingMaster.abi);
const functionName = "returnValidUsersProviderStake";

export function useUsersProviderStakeExists() {
  const { chainId = DEFAULT_CHAIN_ID, account } = useEthers();
  const data = useChainCall(
    !!account && {
      address: STAKING_MASTER_ADDRESS[chainId],
      data: stakesInterface.encodeFunctionData(functionName, [account.address]),
    }
  );
  return useMemo(() => (data ? !BigNumber.from(data).eq(0) : false), [data]);
}
