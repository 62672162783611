import { Contract, Transaction, utils } from "ethers";
import { CurrentTransaction } from ".";
import { TransactionStatus } from "./TransactionStatus";

export const stake = async (
  stakingMaster: Contract,
  amount: string,
  months: string,
  address: string,
  setStatus: (value: TransactionStatus) => void,
  setCurrentTransaction: (value: CurrentTransaction) => void,
  setTransaction?: (value: Transaction) => void
) => {
  try {
    setStatus(TransactionStatus.Mining);
    setCurrentTransaction(CurrentTransaction.StakeUrus);
    const transaction = await stakingMaster.createStaking(
      utils.parseEther(amount),
      months,
      address
    );
    setTransaction && setTransaction(transaction);
    const receipt = await transaction.wait();
    setStatus(
      receipt.status ? TransactionStatus.Success : TransactionStatus.Fail
    );
  } catch (exception) {
    setStatus(TransactionStatus.Fail);
  }
};
