import React, { useState } from "react";
import styled from "styled-components";
import { Colors, DEFAULT_CHAIN_ID, WRAPPED_NATIVE_CURRENCY } from "../../constants";
import {
  useEthers,
  useEthBalance,
  useUrusBalance,
  useLPTokenBalance,
  useStakesTotal,
  useLiquidityDepositTotal,
  useClaimableLiquidityReward,
} from "../../hooks";
import useMigrationContractBalance from "../../hooks/useMigrationContractBalance";
import { ButtonTertiary } from "../common/buttons/ButtonTertiary";
import { ClaimAndStakeLiquidityRewardModal } from "./ClaimAndStakeLiquidityRewardModal";
import { ClaimLiquidityRewardModal } from "./ClaimLiquidityRewardModal";
import { WithdrawModal } from "./WithdrawModal";

export function ConnectedBar() {
  const { chainId } = useEthers();
  const UrusBalance = useUrusBalance();
  const EtherBalance = useEthBalance();
  const LPTokenBalance = useLPTokenBalance();
  const stakedTotal = useStakesTotal();
  const depositedTotal = useLiquidityDepositTotal();
  const claimableLiquidityReward = useClaimableLiquidityReward();
  const migrationContractBalance = useMigrationContractBalance();

  const [claimModalOpen, setClaimModalOpen] = useState(false);
  const [claimAndStakeModalOpen, setClaimAndStakeModalOpen] = useState(false);
  const [isWithdrawModalVisible, setIsWithdrawModalVisible] = useState(false);

  return (
    <DetailsBar>
      <Label>Account balance</Label>
      <UrusAmountWrapper>
        <CurrencyDisplay>
          <UrusAmount>{UrusBalance?.format()}</UrusAmount>
          <LabelUppercase>URUS</LabelUppercase>
        </CurrencyDisplay>
      </UrusAmountWrapper>
      <CurrencyDisplay>
        <Amount>{EtherBalance?.format()}</Amount>
        <LabelUppercase>{WRAPPED_NATIVE_CURRENCY[chainId ?? DEFAULT_CHAIN_ID].symbol}</LabelUppercase>
      </CurrencyDisplay>
      <CurrencyDisplay>
        <Amount>{LPTokenBalance?.format()}</Amount>
        <LabelUppercase>URUS-{WRAPPED_NATIVE_CURRENCY[chainId ?? DEFAULT_CHAIN_ID].symbol} LP</LabelUppercase>
      </CurrencyDisplay>
      <Block>
        <Row>
          <CurrencyDisplay>
            <Amount>{stakedTotal?.format()}</Amount>
            <LabelUppercase>URUS</LabelUppercase>
          </CurrencyDisplay>
          <Label>Staked in total</Label>
        </Row>
      </Block>
      <Block>
        <Row>
          <CurrencyDisplay>
            <Amount>{depositedTotal?.format()}</Amount>
            <LabelUppercase>URUS-{WRAPPED_NATIVE_CURRENCY[chainId ?? DEFAULT_CHAIN_ID].symbol} LP</LabelUppercase>
          </CurrencyDisplay>
          <Label>Deposited tokens</Label>
        </Row>
        <WithdrawButton
          onClick={() => setIsWithdrawModalVisible(true)}
          disabled={depositedTotal?.value.eq(0)}
        >
          Withdraw
        </WithdrawButton>
        <WithdrawModal
          isVisible={isWithdrawModalVisible}
          onClose={() => setIsWithdrawModalVisible(false)}
        />
      </Block>
      <Block>
        <Row>
          <CurrencyDisplay>
            <Amount>{claimableLiquidityReward?.format()}</Amount>
            <LabelUppercase>URUS</LabelUppercase>
          </CurrencyDisplay>
          <Label>Unclaimed reward</Label>
        </Row>
      </Block>

      <ButtonsRow>
        <ButtonTertiary
          onClick={() => setClaimModalOpen(true)}
          disabled={claimableLiquidityReward?.value.eq(0)}
        >
          Claim
        </ButtonTertiary>
        <ButtonTertiary
          onClick={() => setClaimAndStakeModalOpen(true)}
          disabled={claimableLiquidityReward?.value.eq(0)}
        >
          Stake
        </ButtonTertiary>
      </ButtonsRow>
      <ClaimLiquidityRewardModal
        isVisible={claimModalOpen}
        onClose={() => setClaimModalOpen(false)}
        claimableReward={claimableLiquidityReward?.format() ?? ""}
      />
      <ClaimAndStakeLiquidityRewardModal
        isVisible={claimAndStakeModalOpen}
        onClose={() => setClaimAndStakeModalOpen(false)}
        claimableReward={claimableLiquidityReward?.format() ?? ""}
      />
    </DetailsBar>
  );
}

const DetailsBar = styled.div`
  align-self: flex-start;
  max-width: 350px;
  width: 100%;
  padding: 32px 30.5px 43px;
  background: ${Colors.BackgroundSecondary};
  border: 1px solid #1e2739;
  border-radius: 8px;
`;

const UrusAmountWrapper = styled.div`
  margin: 21px 0 11px;
`;

const CurrencyDisplay = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-top: 16px;
  }
`;

const Label = styled.p`
  font-size: 12px;
  line-height: 20px;
  color: ${Colors.GrayAlt};
  opacity: 0.7;
`;

const LabelUppercase = styled(Label)`
  text-transform: uppercase;
`;

const UrusAmount = styled.p`
  margin-right: 8px;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  color: #3799fe;
`;

const Amount = styled.p`
  margin-right: 4px;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: ${Colors.White};
`;

const Block = styled.div`
  padding: 16px 0;
  margin: 30px 0 16px;
  border-top: 1px solid #222b3e;
  border-bottom: 1px solid #222b3e;

  & + & {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }
`;

const WithdrawButton = styled(ButtonTertiary)`
  margin: 16px auto 0;
  display: block;
  max-width: 90px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 16px;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 46px;

  & > button + button {
    margin-left: 24px;
  }
`;
