import { CurrencyValue } from "../model";

export function isSufficientBalance(
  balance: CurrencyValue | undefined,
  value: CurrencyValue | undefined
) {
  return balance === undefined || value === undefined
    ? false
    : balance.value.gte(value.value);
}
