import { Contract } from "ethers";
import { TransactionStatus } from "./TransactionStatus";

export const cancelStake = async (
  stakingMaster: Contract,
  stakeAddress: string,
  setStatus: (value: TransactionStatus) => void
) => {
  try {
    setStatus(TransactionStatus.Mining);
    const transaction = await stakingMaster.closeStake(stakeAddress);
    const receipt = await transaction.wait();
    setStatus(
      receipt.status ? TransactionStatus.Success : TransactionStatus.Fail
    );
  } catch (error) {
    setStatus(TransactionStatus.Fail);
  }
};
