import { Interface } from "@ethersproject/abi";
import { useMemo } from "react";
import { DEFAULT_CHAIN_ID, URUS_LP_TOKEN } from "../constants";
import { useChainCall } from "./useChainCalls";
import { useEthers } from "./useEthers";

const reservesInterface = new Interface([
  "function getReserves() external view returns (uint112 reserve0, uint112 reserve1, uint32 blockTimestampLast)",
]);
const functionName = "getReserves";

export function useReserves() {
  const { chainId = DEFAULT_CHAIN_ID } = useEthers();
  const token = URUS_LP_TOKEN[chainId];

  const data = useChainCall(
    token.chainId === chainId && {
      address: token.address,
      data: reservesInterface.encodeFunctionData(functionName, []),
    }
  );

  return useMemo(() => {
    if (data !== undefined) {
      const [reserve0, reserve1] = reservesInterface.decodeFunctionResult(
        functionName,
        data
      );
      if (reserve0 && reserve1) {
        return [reserve0, reserve1];
      }
      return undefined;
    }
  }, [data]);
}
