import React from "react";
import styled from "styled-components";
import triangleIcon from "../../assets/icons/triangle.svg";
import { useUsersStakes } from "../../hooks/useUsersStakes";
import { StakingTableRow } from "./StakingTableRow";

export const StakingTable = () => {
  const stakes = useUsersStakes();

  return (
    <Table>
      <thead>
        <tr>
          <Th>
            <SortButton>Start date</SortButton>
          </Th>
          <Th>
            <SortButton>Amount of stake</SortButton>
          </Th>
          <Th>
            <SortButton>Amount of Rewards</SortButton>
          </Th>
          <Th>
            <SortButton>Claimable Rewards</SortButton>
          </Th>
          <Th>
            <SortButton>Remaining time</SortButton>
          </Th>
          <Th />
        </tr>
      </thead>
      <tbody>
        {stakes?.map((address) => (
          <StakingTableRow address={address} key={address} />
        ))}
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
`;

const Th = styled.th`
  padding: 0 16px 8px;

  &:first-child {
    text-align: left;
  }
`;

const SortButton = styled.button`
  position: relative;
  padding: 0 15px 0 0;
  font-size: 10px;
  line-height: 20px;
  color: #737f99;
  background: none;
  border: none;

  &::after {
    content: "";
    position: absolute;
    top: 6px;
    right: 0;
    width: 10px;
    height: 8px;
    background: url(${triangleIcon}) center no-repeat;
    background-size: contain;
  }
`;
