import React from "react";
import styled from "styled-components";
import { Colors } from "../../constants";
import { useEthers } from "../../hooks";
import { ConnectedBar } from "./ConnectedBar";

export function SideBar() {
  const { account } = useEthers();
  if (!account) {
    return <Title>The Aurox Token powers the entire Aurox Ecosystem</Title>;
  }
  return <ConnectedBar />;
}

const Title = styled.h1`
  max-width: 350px;
  margin-right: 32px;
  font-weight: normal;
  font-size: 56px;
  line-height: 72px;
  color: ${Colors.White};
`;
