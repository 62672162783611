import { BigNumber } from "ethers";
import { ChainId, DEFAULT_CHAIN_ID, WRAPPED_NATIVE_CURRENCY } from "../../constants";
import { formatValueString } from "../../helpers";
import { Fraction } from "./Fraction";

export class TokenPrice {
  readonly fraction: Fraction;

  private constructor(
    readonly address: string,
    readonly chainId = DEFAULT_CHAIN_ID,
    fraction: Fraction
  ) {
    this.fraction = fraction;
  }

  static createFrom(
    address: string,
    chainId = DEFAULT_CHAIN_ID,
    nominator: BigNumber,
    denominator: BigNumber,
    decimals = 18
  ) {
    const fraction = Fraction.from(nominator, 18, denominator, decimals);
    return new TokenPrice(address, chainId, fraction);
  }

  format(scale: number) {
    return this.fraction.format(scale);
  }

  toString() {
    return this.fraction.toString();
  }

  toFormattedString(precision: number) {
    const asString = this.toString();
    return formatValueString(asString, precision);
  }

  toNumber() {
    return parseFloat(this.toString());
  }

  static fromReserves(
    address: string,
    chainId: ChainId,
    reserve0: BigNumber,
    reserve1: BigNumber,
    decimals = 18
  ): TokenPrice {
    return BigNumber.from(WRAPPED_NATIVE_CURRENCY[chainId].address).lt(
      BigNumber.from(address)
    )
      ? TokenPrice.createFrom(address, chainId, reserve0, reserve1, decimals)
      : TokenPrice.createFrom(address, chainId, reserve1, reserve0, decimals);
  }

  reverse() {
    return new TokenPrice(this.address, this.chainId, this.fraction.reverse());
  }
}

export const ONE = BigNumber.from(1);
