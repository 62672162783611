import { Transaction } from "ethers";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ChainId, Colors } from "../../constants";
import { shortenHash } from "../../helpers";
import { SectionLabel } from "./Section";

type InfoProps = {
  transaction: Transaction;
};

const blockExplorer = {
  [ChainId.Mainnet]: { url: "https://etherscan.io", name: "Etherscan" },
  [ChainId.Goerli]: { url: "https://goerli.etherscan.io", name: "Goerliscan" },
  [ChainId.Hardhat]: { url: "", name: "" },
  [ChainId.Binance]: { url: "https://bscscan.com", name: "BSCScan" },
};

export function TransactionInfo({ transaction }: InfoProps) {
  const chainId: ChainId =
    transaction.chainId in blockExplorer ? transaction.chainId : ChainId.Mainnet;
  const [hint, setHint] = useState(false);
  useEffect(() => {
    const handler = setTimeout(() => setHint(true), 45000);
    return () => clearTimeout(handler);
  }, [transaction]);
  return (
    <InfoSection>
      <Label>
        Transaction {transaction.hash && shortenHash(transaction.hash)}
      </Label>
      <InfoLink
        target="_blank"
        href={
          transaction.hash &&
          `${blockExplorer[chainId].url}/tx/${transaction.hash}`
        }
      >
        View on {blockExplorer[chainId].name}
      </InfoLink>
      {hint && (
        <Text>
          Transaction is taking longer than expected. Consider speeding it up in
          your wallet extension.
        </Text>
      )}
    </InfoSection>
  );
}

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px 0 0;
  padding: 16px;
  background: rgba(36, 125, 255, 0.15);
  border-radius: 12px;
  text-align: center;
`;

const Label = styled(SectionLabel)`
  color: #78a8f8;
`;

const Text = styled.p`
  color: #78a8f8;
  text-align: center;
  font-size: 12px;
  margin-top: 4px;
`;

const InfoLink = styled.a`
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.Blue};
`;
