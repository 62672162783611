import { useEthers } from "./useEthers";
import { CurrencyValue } from "../model";
import {
  DEFAULT_CHAIN_ID,
  PROVIDER_ADDRESS,
  URUS_LP_TOKEN,
} from "../constants";
import { useChainCall } from "./useChainCalls";
import { Interface } from "@ethersproject/abi";
import { BigNumber } from "@ethersproject/bignumber";
import Provider from "../abi/Provider.json";

const investmentInterface = new Interface(Provider.abi);

export function useLiquidityDepositTotal() {
  const { chainId = DEFAULT_CHAIN_ID, account } = useEthers();
  const address = PROVIDER_ADDRESS[chainId];
  const token = URUS_LP_TOKEN[chainId];

  try {
    const data = useChainCall(
      !!account && {
        address,
        data: investmentInterface.encodeFunctionData(
          "returnUsersInvestmentTotal",
          [account.address]
        ),
      }
    );

    return data !== undefined
      ? new CurrencyValue(token, BigNumber.from(data))
      : undefined;
  } catch (e) {
    return new CurrencyValue(token, BigNumber.from("0"));
  }
}
