export enum ChainId {
  Mainnet = 1,
  Goerli = 5,
  Hardhat = 31337,
  Binance = 56,
}

export type ChainName = "mainnet" | "binance";

export const SUPPORTED_CHAINS = [
  ChainId.Mainnet,
  ChainId.Goerli,
  ChainId.Binance,
];

export const CHAIN_NAMES: ChainName[] = ["mainnet", "binance"];
export const CHAIN_METADATA: Partial<Record<ChainName, { 
  chainId: ChainId,
  chainName: ChainName,
  chainFullName: string, 
}>> = {
  mainnet: { 
    chainId: ChainId.Mainnet,
    chainName: "mainnet",
    chainFullName: "Ethereum",
   },
  binance: { 
    chainId: ChainId.Binance,
    chainName: "binance",
    chainFullName: "Binance Smart Chain",
   },
}

export const CHAIN_ID_TO_CHAIN_NAME_MAPPING: Partial<Record<ChainId, ChainName>> = {
  [ChainId.Mainnet]: "mainnet",
  [ChainId.Binance]: "binance",
}

export const CHAIN_NAME_TO_CHAIN_ID_MAPPING: Partial<Record<ChainName, ChainId>> = {
  mainnet: ChainId.Mainnet,
  binance: ChainId.Binance,
}

export const DEFAULT_CHAIN_ID = ChainId.Mainnet;
export const DEFAULT_CHAIN_NAME = "mainnet";
