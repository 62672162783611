import React, { useMemo } from "react";
import styled from "styled-components";

import { ChainId, Colors, DEFAULT_CHAIN_ID } from "../../constants";
import { useEthers, useTransactions } from "../../hooks";
import { StoredTransaction } from "../../providers";
import { Modal } from "../common/modal/Modal";

import { Spinner } from "./Spinner";

interface Props {
  onClose: () => void;
  isVisible: boolean;
}

export function HistoryModal({ onClose, isVisible }: Props) {
  const { transactions } = useTransactions();
  const reversed = useMemo(() => [...transactions].reverse(), [transactions]);

  return (
    <Modal isVisible={isVisible} title="Transactions" onClose={onClose}>
      {reversed.length === 0 && (
        <p>The list of your transactions will appear here</p>
      )}
      <List>
        {reversed.map((tx) => (
          <TransactionItem key={tx.hash} tx={tx} />
        ))}
      </List>
    </Modal>
  );
}

function getEtherscanLink(transaction: StoredTransaction, chainId: ChainId) {
  switch (chainId) {
    case ChainId.Mainnet:
      return `https://etherscan.io/tx/${transaction.hash}`;
    case ChainId.Goerli:
      return `https://goerli.etherscan.io/tx/${transaction.hash}`;
    case ChainId.Binance:
      return `https://bscscan.com/tx/${transaction.hash}`;
    default:
      return "";
  }
}

function TransactionItem({ tx }: { tx: StoredTransaction }) {
  const { chainId = DEFAULT_CHAIN_ID } = useEthers();
  return (
    <ListItem>
      {!tx.receipt && <Spinner />}{" "}
      <a href={getEtherscanLink(tx, chainId)} target="_blank" rel="noreferrer">
        {tx.description}
      </a>
    </ListItem>
  );
}

const List = styled.ol`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  margin-bottom: 12px;

  a {
    color: ${Colors.Black};
  }
`;
