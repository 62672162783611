import styled from "styled-components";
import { Colors } from "../../../constants";

export const ButtonTertiary = styled.button`
  padding: 10px 15px;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.White};
  background: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  border: none;

  &:disabled {
    background: #161e2e;
    color: #545a66;
    cursor: not-allowed;
  }
`;
