import React, { useState } from "react";
import styled from "styled-components";
import { ButtonPrimary } from "../../components/common/buttons/ButtonPrimary";
import { PageSubtitle } from "../../components/common/text/PageSubtitle";
import { PageTitle } from "../../components/common/text/PageTitle";
import { StakeModal } from "./StakeModal";
import { Modal } from "../../components/common/modal/Modal";
import { StakingTable } from "./StakingTable";

export function Staking() {
  const [isStakingModal, setStakingModal] = useState(false);
  return (
    <>
      <StakingBody>
        <Row>
          <div>
            <PageTitle>Aurox Staking</PageTitle>
            <PageSubtitle>List of stakes below</PageSubtitle>
          </div>
          <StakeButton onClick={() => setStakingModal(true)}>Stake</StakeButton>
        </Row>
        <StakingTable />
      </StakingBody>
      <Modal
        isVisible={isStakingModal}
        title="Transactions"
        onClose={() => setStakingModal(false)}
      >
        <StakeModal closeOnSuccess={() => setStakingModal(false)} />
      </Modal>
    </>
  );
}

const StakingBody = styled.div`
  align-self: flex-start;
  width: 100%;
  margin-left: 30px;
`;

const StakeButton = styled(ButtonPrimary)`
  padding: 10px 36px;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 24px;
`;
