import { BigNumber } from "ethers";
import { Interface } from "ethers/lib/utils";
import StakingMaster from "../abi/StakingMaster.json";

const stakesInterface = new Interface(StakingMaster.abi);

export type Stake = {
  currentStakeValue: BigNumber;
  stakeEndTime: BigNumber;
  interestRate: BigNumber;
  lastUpdate: BigNumber;
  compounding: boolean;
  rawInvestedAmount: BigNumber;
  stakeStartTime: BigNumber;
  address: string;
};

export function decodeStake(
  data: string | undefined,
  functionName: string,
  stakeAddress: string
): Stake | undefined {
  if (!data) {
    return undefined;
  }
  const asArray = stakesInterface.decodeFunctionResult(functionName, data);

  return {
    currentStakeValue: asArray[0],
    stakeEndTime: asArray[1],
    interestRate: asArray[2],
    lastUpdate: asArray[3],
    compounding: asArray[4],
    rawInvestedAmount: asArray[5],
    stakeStartTime: asArray[6],
    address: stakeAddress,
  };
}
