import React, { useState } from "react";
import styled from "styled-components";

import { Colors } from "../../constants";
import { useEthBalance, useTransactions } from "../../hooks";
import { shortenAddress } from "../../utils";
import { JazzIcon } from "../icons";

import { HistoryModal } from "./HistoryModal";
import { Spinner } from "./Spinner";

interface Props {
  address: string;
}

export function Account({ address }: Props) {
  const ethBalance = useEthBalance();
  const [open, setOpen] = useState(false);
  const { transactions } = useTransactions();

  const pendingCount = transactions.filter((x) => !x.receipt).length;

  return (
    <>
      <Container>
        {ethBalance && (
          <BalanceArea>{ethBalance.formatWithSymbol()}</BalanceArea>
        )}
        <AccountArea onClick={() => setOpen(true)}>
          {pendingCount === 0 && (
            <>
              <Address>{shortenAddress(address)}</Address>
              <JazzIcon address={address} size={16} />
            </>
          )}
          {pendingCount > 0 && (
            <>
              <Address>{pendingCount} Pending</Address>
              <BigSpinner />
            </>
          )}
        </AccountArea>
      </Container>
      <HistoryModal isVisible={open} onClose={() => setOpen(false)} />
    </>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 6px;
  padding: 1px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
`;

const BalanceArea = styled.div`
  padding: 9px 7px 7px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.White};
  text-transform: uppercase;
`;

const AccountArea = styled.button`
  display: flex;
  align-items: center;
  padding: 9px 8px;
  background: ${Colors.MainBackground};
  border-radius: 8px;
  border: none;
  outline: none;
`;

const Address = styled.span`
  margin-right: 10px;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.White};
`;

const BigSpinner = styled(Spinner)`
  font-size: 16px;
  bottom: 0;
  color: ${Colors.White};
`;
