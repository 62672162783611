import React from "react";
import { FailSection } from ".";
import { SectionLabelDanger } from "./Section";

export function FailMessage() {
  return (
    <FailSection>
      <SectionLabelDanger>Transaction failed.</SectionLabelDanger>
    </FailSection>
  );
}
