import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { Colors } from "../../constants";

import { Logo } from "./Logo";

export function Navigation() {
  return (
    <nav>
      <NavList>
        <NavListItem>
          <StyledLink to="/home">
            <Logo />
          </StyledLink>
        </NavListItem>
        <NavListItem>
          <StyledLink to="/home">Home</StyledLink>
        </NavListItem>
        <NavListItem>
          <StyledLink to="/mining">Liquidity mining</StyledLink>
        </NavListItem>
        <NavListItem>
          <StyledLink to="/staking">Staking</StyledLink>
        </NavListItem>
      </NavList>
    </nav>
  );
}

const NavList = styled.ul`
  display: flex;
  align-items: center;
`;

const NavListItem = styled.li`
  & + & {
    margin-left: 40px;
  }
`;

const StyledLink = styled(NavLink)`
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.White};

  &.active {
    color: ${Colors.LightBlue};
  }
`;
