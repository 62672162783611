import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Colors, MAX_STAKE_DURATION } from "../../constants";
import { toPercent } from "../../helpers";
import {
  useContracts,
  useInterestPercentage,
  useNaturalNumberInput,
  useUsersProviderStakeExists,
} from "../../hooks";
import { TransactionStatus } from "../../infrastructure";
import { claimAndStakeLiquidityReward } from "../../infrastructure/claimLiquidityReward";
import { CustomSpinnerInput, FailMessage } from "../common";
import { Badge } from "../common/Badge";
import { ButtonPrimary } from "../common/buttons/ButtonPrimary";
import { Modal } from "../common/modal/Modal";
import { Section } from "../common/Section";
import { TextRow } from "../common/text/TextRow";
import { HelpIcon } from "../icons";

interface ClaimAndStakeLiquidityRewardModalProps {
  isVisible: boolean;
  onClose: () => void;
  claimableReward: string;
}

export const ClaimAndStakeLiquidityRewardModal = ({
  isVisible,
  onClose,
  claimableReward,
}: ClaimAndStakeLiquidityRewardModalProps) => {
  const { provider } = useContracts();
  const [status, setStatus] = useState(TransactionStatus.New);
  const [stakeTime, setStakeTime] = useNaturalNumberInput(MAX_STAKE_DURATION);

  const isMined = status === TransactionStatus.Success;
  const isMining = status === TransactionStatus.Mining;
  const apy = useInterestPercentage(stakeTime, true);
  const hasProviderStake = useUsersProviderStakeExists();
  useEffect(
    () => (status === TransactionStatus.Success ? onClose() : undefined),
    [status]
  );

  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      title="Claim and Stake Liquidity Reward"
    >
      <TextRow label="Pool reward" value={<b>{claimableReward} URUS</b>} />

      {!hasProviderStake ? (
        <>
          <MonthInput>
            <CustomSpinnerInput value={stakeTime} set={setStakeTime} />
            <MonthLabel>months</MonthLabel>
          </MonthInput>
          <Hint>
            <HelpIcon />
            <HintText>You can stake from 1 month to 7 years</HintText>
          </Hint>
          <InfoBox>
            <StyledBadge>{apy ? toPercent(apy) : "...%"} APY</StyledBadge>
            By staking your rewards directly, you will receive a 50% increase in
            APY to your stake APY during Epoch 1 and 25% increase in Epoch 2.
            Refer to our documentation:{" "}
            <InfoLink
              href="https://docs.getaurox.com/product-docs/aurox-token-guides/token-staking-information"
              target="_blank"
            >
              Click Here
            </InfoLink>
          </InfoBox>
        </>
      ) : (
        <InfoBox>
          You have already staked your Liquidity Mining rewards and that stake
          is in progress. Instead of creating a new stake, you can add your
          reward to the stake that you already have.
        </InfoBox>
      )}
      <ClaimButton
        isFullWidth
        disabled={hasProviderStake ? isMined : isMined || stakeTime === "0"}
        isLoading={isMining}
        onClick={() =>
          claimAndStakeLiquidityReward(provider, stakeTime, setStatus)
        }
      >
        {isMined
          ? "Done!"
          : isMining
          ? "Staking..."
          : hasProviderStake
          ? "Add to stake"
          : "Stake"}
      </ClaimButton>
      {status === TransactionStatus.Fail && <FailMessage />}
    </Modal>
  );
};

const ClaimButton = styled(ButtonPrimary)`
  margin-top: 41px;
`;

const Hint = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 54px;
`;

const HintText = styled.p`
  margin-left: 8px;
  font-size: 12px;
  line-height: 14px;
  color: ${Colors.PrimaryTextColor};
`;

const InfoBox = styled(Section)`
  position: relative;
  margin-bottom: 32px;
  padding: 32px 32px 16px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${Colors.White};
`;

const StyledBadge = styled(Badge)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 8px 28px;
`;

const MonthInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin-top: 0;
`;

const MonthLabel = styled.p`
  font-size: 16px;
  line-height: 18px;
  margin-left: 10px;
  margin-top: 10px;
  color: ${Colors.PrimaryTextColor};
`;

const InfoLink = styled.a`
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.Blue};
`;
