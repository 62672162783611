import { BigNumber } from "ethers";
import { Interface } from "ethers/lib/utils";
import { useMemo } from "react";
import Provider from "../abi/Provider.json";
import { DEFAULT_CHAIN_ID, PROVIDER_ADDRESS } from "../constants";
import { useChainCall } from "./useChainCalls";
import { useEthers } from "./useEthers";

const providerInterface = new Interface(Provider.abi);
const functionName = "returnCurrentEpoch";

export function useCurrentEpoch() {
  const { chainId = DEFAULT_CHAIN_ID } = useEthers();
  const data = useChainCall({
    address: PROVIDER_ADDRESS[chainId],
    data: providerInterface.encodeFunctionData(functionName, []),
  });
  return useMemo(() => (data ? BigNumber.from(data) : undefined), [data]);
}
