import { utils } from "ethers";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { WarningSection, WarningText } from "../../components/common";
import { ButtonPrimary } from "../../components/common/buttons/ButtonPrimary";
import { Modal } from "../../components/common/modal/Modal";
import {
  Section,
  SectionLabel,
  SectionLabelDanger,
} from "../../components/common/Section";
import { Colors } from "../../constants";
import { useContracts } from "../../hooks";
import { useAmountForStakeCancel } from "../../hooks/useAmountForStakeCancel";
import { TransactionStatus } from "../../infrastructure";
import { cancelStake } from "../../infrastructure/cancelStake";
import { Stake } from "../../model/Stake";
import { remainingTime } from "./helpers";

type CancelModalProps = {
  isVisible: boolean;
  onClose: () => void;
  stake: Stake;
};

export function CancelStakeModal({
  isVisible,
  onClose,
  stake,
}: CancelModalProps) {
  const amount = useAmountForStakeCancel(stake.address);
  const { stakingMaster } = useContracts();
  const [status, setStatus] = useState(TransactionStatus.New);
  useEffect(
    () => (status == TransactionStatus.Success ? onClose() : undefined),
    [status]
  );

  const isMining = status == TransactionStatus.Mining;

  return (
    <Modal isVisible={isVisible} onClose={onClose} title="Cancel stake">
      <TimeRow>
        <SectionLabel>Remaining time: </SectionLabel>
        <SectionLabelDanger>
          {remainingTime(stake.stakeEndTime)}
        </SectionLabelDanger>
      </TimeRow>
      <Column>
        <CancelModalSection>
          {amount ? utils.formatEther(amount) : undefined} <span>URUS</span>
        </CancelModalSection>
        <SectionLabel>Your return amount</SectionLabel>
      </Column>
      <WarningSection>
        <SectionLabelDanger>Warning! Penalty information</SectionLabelDanger>
        <WarningText>
          Canceling the stake before it&apos;s over will only refund a portion
          of the staked URUS.
        </WarningText>
      </WarningSection>
      <ButtonDanger
        isFullWidth
        onClick={() => cancelStake(stakingMaster, stake.address, setStatus)}
        isLoading={isMining}
      >
        {!isMining ? "Cancel stake" : "Processing transaction"}
      </ButtonDanger>
    </Modal>
  );
}

const TimeRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  & > * + * {
    margin-left: 2px;
  }
`;

const CancelModalSection = styled(Section)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 8px;
  padding: 16px 34px;
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  color: ${Colors.White};

  span {
    font-weight: 400;
    margin-left: 8px;
    font-size: 12px;
    line-height: 20px;
    color: ${Colors.GrayAlt};
    opacity: 0.6;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonDanger = styled(ButtonPrimary)`
  background: ${Colors.Danger};
`;
