import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Colors } from "../../constants";
import { Badge } from "../common/Badge";
import graphIcon from "../../assets/icons/graph.svg";
import starIcon from "../../assets/icons/star.svg";
import walletIcon from "../../assets/icons/wallet.svg";

export function HomeWidgets() {
  return (
    <WidgetsCascade>
      <Widget>
        <WidgetHeader>
          <img src={graphIcon} alt="graph" />
          <WidgetTitle>Aurox Liquidity Mining</WidgetTitle>
          <div>
            <Badge>150 / Week</Badge>
          </div>
        </WidgetHeader>
        <WidgetRow>
          <WidgetDescription>
            <WidgetText>
              The liquidity mining rewards are distributed to users that help us create a liquid decentralized market. Rewards are distributed evenly based on contribution. Liquidity mining is ran in 2 week epochs. You will have 24 hours to withdraw your LP tokens after the epoch has ended, otherwise, they will be automatically transferred to the subsequent epoch
            </WidgetText>
            <List>
              <ListItem>
                Provide liquidity to the Aurox Token listing on either Uniswap or PancakeSwap. By completing subsequent epochs, receive an additional reward.
              </ListItem>
              <ListItem>
                Stake your rewards earned via liquidity mining for 1 year and earn increase in staking rewards!
              </ListItem>
            </List>
          </WidgetDescription>
          <div>
            <InfoLink
              href="https://docs.getaurox.com/product-docs/aurox-token-guides/liquidity-mining-information"
              target="_blank"
            >
              How to start?
            </InfoLink>
            <Button to="/mining">Deposit</Button>
          </div>
        </WidgetRow>
      </Widget>
      <Widget>
        <WidgetHeader>
          <img src={walletIcon} alt="graph" />
          <WidgetTitle>Aurox Staking</WidgetTitle>
        </WidgetHeader>
        <WidgetRow>
          <WidgetDescription>
            <WidgetText>
              Stake your Aurox Tokens to earn more Aurox Tokens!
            </WidgetText>
            <List>
              <ListItem>
                Stake your tokens for more than 1 year to receive increase in rewards.
              </ListItem>
              <ListItem>
                Stake up to 7 years in 1 month increments.
              </ListItem>
            </List>
          </WidgetDescription>
          <div>
            <InfoLink
              href="https://docs.getaurox.com/product-docs/aurox-token-guides/token-staking-information"
              target="_blank"
            >
              How to start?
            </InfoLink>
            <Button to="/staking">Stake</Button>
          </div>
        </WidgetRow>
      </Widget>
    </WidgetsCascade>
  );
}

const WidgetsCascade = styled.div`
  width: 100%;
  margin-left: 70px;
`;

const WidgetWithBorder = styled.div`
  padding: 24px 40px 32px;
  background: #0e1930;
  border-radius: 8px;
  border: 2px solid orange;

  & + & {
    margin-top: 40px;
  }
`;

const Widget = styled.div`
  padding: 24px 40px 32px;
  background: #0e1930;
  border-radius: 8px;

  margin-top: 40px;
`;

const WidgetHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

const WidgetTitle = styled.h2`
  margin: 0 auto 0 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${Colors.White};
`;

const WidgetRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const WidgetDescription = styled.div`
  max-width: 454px;
  margin-right: 40px;
`;

const WidgetText = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${Colors.White};
  opacity: 0.8;
`;

const List = styled.ul`
  margin-top: 24px;
`;

const ListItem = styled.li`
  position: relative;
  padding-left: 20px;
  font-size: 12px;
  line-height: 16px;
  color: #99a7c6;

  & + & {
    margin-top: 16px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 1.5px;
    left: 0;
    width: 14px;
    height: 14px;
    background: url(${starIcon}) center no-repeat;
    background-size: contain;
  }
`;

const InfoLink = styled.a`
  display: block;
  margin-bottom: 16px;
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.Blue};
`;

const Button = styled(Link)`
  display: inline-block;
  margin-top: 16px;
  padding: 12px 50px;
  border: 2px solid #ffffff;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.White};
  background: none;
`;
