import React from "react";
import styled from "styled-components";

export function BackIcon() {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7685 17.36L7.13549 13L6.00098 12.0001L7.13549 11L10.7685 6.64C11.1225 6.216 11.0645 5.585 10.6405 5.232C10.4535 5.076 10.2265 5 10.0005 5C9.71449 5 9.43049 5.123 9.23149 5.36L4.23149 11.36C4.20513 11.3918 4.18791 11.4272 4.17056 11.4629C4.16224 11.48 4.15389 11.4971 4.14449 11.514C4.1364 11.5282 4.12775 11.5416 4.11914 11.555C4.1022 11.5814 4.08543 11.6075 4.07349 11.638C4.02849 11.753 4.00149 11.874 4.00149 11.996L4.00049 12L4.00149 12.004C4.00149 12.126 4.02849 12.247 4.07349 12.362C4.08543 12.3925 4.1022 12.4186 4.11914 12.445C4.12775 12.4584 4.1364 12.4719 4.14449 12.486C4.15389 12.5029 4.16224 12.52 4.17056 12.5371C4.18791 12.5728 4.20513 12.6082 4.23149 12.64L9.23149 18.64C9.58549 19.064 10.2155 19.122 10.6405 18.768C11.0645 18.415 11.1225 17.784 10.7685 17.36Z"
        fill="white"
      />
      <mask
        id="mask0"
        maskUnits="userSpaceOnUse"
        x="4"
        y="5"
        width="17"
        height="14"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0005 13H7.13549L10.7685 17.36C11.1225 17.784 11.0645 18.415 10.6405 18.768C10.2155 19.122 9.58549 19.064 9.23149 18.64L4.23149 12.64C4.19249 12.593 4.17349 12.538 4.14449 12.486C4.12049 12.444 4.09149 12.408 4.07349 12.362C4.02849 12.247 4.00149 12.126 4.00149 12.004C4.00149 12.003 4.00049 12.001 4.00049 12C4.00049 11.999 4.00149 11.997 4.00149 11.996C4.00149 11.874 4.02849 11.753 4.07349 11.638C4.09149 11.592 4.12049 11.556 4.14449 11.514C4.17349 11.462 4.19249 11.407 4.23149 11.36L9.23149 5.36C9.43049 5.123 9.71449 5 10.0005 5C10.2265 5 10.4535 5.076 10.6405 5.232C11.0645 5.585 11.1225 6.216 10.7685 6.64L7.13549 11H19.0005C19.5525 11 20.0005 11.448 20.0005 12C20.0005 12.552 19.5525 13 19.0005 13Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect
          width="24"
          height="24"
          transform="matrix(-1 0 0 1 24 0)"
          fill="white"
        />
      </g>
    </SvgIcon>
  );
}

const SvgIcon = styled.svg`
  width: 24px;
  height: 24px;
`;
