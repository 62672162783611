import { utils } from "ethers";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { SettingsIcon } from "../../components";
import { ButtonTertiary } from "../../components/common/buttons/ButtonTertiary";
import { Colors } from "../../constants";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { useStakeState } from "../../hooks/useStakeState";
import { useToggler } from "../../hooks/useToggler";
import { CancelStakeModal } from "./CancelStakeModal";
import { isStakeReady, remainingTime, toMilliseconds } from "./helpers";
import { AmountOfRewards } from "./AmountOfRewards";
import { RewardAmount } from "./RewardAmount";
import { ClaimStakeModal } from "./ClaimStakeModal";

interface StakingTableRowProps {
  address: string;
}

export const StakingTableRow = ({ address }: StakingTableRowProps) => {
  const { visible, toggle } = useToggler();
  const ref = useRef(null);
  useOutsideClick(ref, () => toggle(false));
  const data = useStakeState(address);
  const [cancelVisible, setCancelVisible] = useState(false);
  const [claimModalVisible, setClaimModalVisible] = useState(false);

  return (
    <Tr>
      <Td>
        {data
          ? new Date(toMilliseconds(data.stakeStartTime)).toLocaleDateString()
          : undefined}
      </Td>
      <Td>
        {data ? utils.formatEther(data.rawInvestedAmount) : undefined}
        <span>URUS</span>
      </Td>
      <Td>
        {data ? <AmountOfRewards stake={data} /> : undefined} <span>URUS</span>
      </Td>
      <Td>
        {data ? <RewardAmount stake={data} /> : undefined} <span>URUS</span>
      </Td>
      <Td>
        <RemainingTime isReady={data ? isStakeReady(data.stakeEndTime) : false}>
          {data ? remainingTime(data.stakeEndTime) : undefined}
        </RemainingTime>
      </Td>
      <Td>
        <ButtonsRow>
          <ClaimButton
            disabled={!data || !isStakeReady(data.stakeEndTime)}
            onClick={() => setClaimModalVisible(true)}
          >
            Claim
          </ClaimButton>
          <Settings ref={ref}>
            <SettingsButton onClick={() => toggle()}>
              <SettingsIcon />
            </SettingsButton>
            {visible && (
              <SettingsList>
                <SettingsOption onClick={() => setCancelVisible(true)}>
                  Cancel stake
                </SettingsOption>
              </SettingsList>
            )}
          </Settings>
        </ButtonsRow>
        {cancelVisible && data && !isStakeReady(data.stakeEndTime) && (
          <CancelStakeModal
            isVisible={cancelVisible}
            onClose={() => setCancelVisible(false)}
            stake={data}
          />
        )}
        {data && (
          <ClaimStakeModal
            isVisible={claimModalVisible}
            onClose={() => setClaimModalVisible(false)}
            stake={data}
          />
        )}
      </Td>
    </Tr>
  );
};

const Tr = styled.tr`
  border-radius: 8px;

  &:first-child td:first-child {
    border-top-left-radius: 8px;
  }

  &:first-child td:last-child {
    border-top-right-radius: 8px;
  }

  &:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }

  &:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }

  &:first-child td {
    padding-top: 24px;
    border-top: 1px solid ${Colors.BorderPrimary};
  }

  &:last-child td {
    padding-bottom: 24px;
    border-bottom: 1px solid ${Colors.BorderPrimary};
  }
`;

const Td = styled.td`
  font-size: 12px;
  line-height: 20px;
  color: ${Colors.White};
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  background: ${Colors.BackgroundSecondary};
  text-align: center;

  &:first-child {
    text-align: left;
    border-left: 1px solid ${Colors.BorderPrimary};
  }

  &:last-child {
    border-right: 1px solid ${Colors.BorderPrimary};
  }

  span {
    margin-left: 6px;
    color: #75819c;
    font-size: 12px;
    line-height: 20px;
  }

  b {
    font-weight: 900;
  }
`;

interface RemainingTimeProps {
  isReady: boolean;
}

const RemainingTime = styled.p<RemainingTimeProps>`
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${({ isReady }) => (isReady ? Colors.Success : Colors.Danger)};
`;

const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
`;

const ClaimButton = styled(ButtonTertiary)`
  padding: 8px 14px;
`;

const Settings = styled.div`
  position: relative;
  margin-left: 24px;
`;

const SettingsButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  padding: 0;
  background: none;
  border: none;
`;

const SettingsList = styled.div`
  position: absolute;
  left: 8px;
  bottom: 0;
  transform: translate(-100%, 100%);
  padding: 8px 16px;
  background: #070e1d;
  border: 1px solid ${Colors.BorderPrimary};
  border-radius: 8px;
`;

const SettingsOption = styled.button`
  padding: 8px 0;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${Colors.Danger};
  background: none;
  border: none;
  white-space: nowrap;
`;
