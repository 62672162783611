import { Contract, utils } from "ethers";
import { TransactionStatus } from "./TransactionStatus";

export const withdraw = async (
  provider: Contract,
  amount: string,
  setStatus: (value: TransactionStatus) => void
) => {
  try {
    setStatus(TransactionStatus.Mining);
    const transaction = await provider.removeLiquidity(
      utils.parseEther(amount)
    );
    const receipt = await transaction.wait();
    setStatus(
      receipt.status ? TransactionStatus.Success : TransactionStatus.Fail
    );
  } catch (exception) {
    setStatus(TransactionStatus.Fail);
  }
};
