import React from "react";
import styled from "styled-components";

import { Connection } from "./Connection";
import { Navigation } from "./Navigation";

export function TopBar() {
  return (
    <Bar>
      <Navigation />
      <Connection />
    </Bar>
  );
}

const Bar = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 0;
`;
