import { Contract, Transaction, utils } from "ethers";
import { TransactionStatus } from "./TransactionStatus";

export const addLiquidity = async (
  uniswapRouter: Contract,
  auroxToken: Contract,
  ethAmount: string,
  urusAmount: string,
  to: string,
  deadline: number,
  setStatus: (value: TransactionStatus) => void,
  setTransaction?: (value: Transaction) => void
) => {
  try {
    setStatus(TransactionStatus.Mining);
    const token = auroxToken.address;
    const amountTokenDesired = urusAmount;
    const amountTokenMin = 0;
    const amountETHMin = 0;

    const amountTokenDesiredWei = utils.parseEther(amountTokenDesired);
    const ethAmountWei = utils.parseEther(ethAmount);

    const transaction = await uniswapRouter.addLiquidityETH(
      token,
      amountTokenDesiredWei,
      amountTokenMin,
      amountETHMin,
      to,
      deadline,
      { value: ethAmountWei }
    );
    setTransaction && setTransaction(transaction);

    const receipt = await transaction.wait();
    setStatus(
      receipt.status ? TransactionStatus.Success : TransactionStatus.Fail
    );
  } catch (exception) {
    console.error(exception);
    setStatus(TransactionStatus.Fail);
  }
};
