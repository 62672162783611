import React, { useState } from "react";
import styled from "styled-components";
import { ButtonPrimary } from "../../components/common/buttons/ButtonPrimary";
import { ModalOverlay } from "../../components/common/modal/ModalOverlay";
import { ModalTitle } from "../../components/common/modal/ModalTitle";
import { Row } from "../../components/common/Row";
import { CloseIcon } from "../../components/icons/CloseIcon";
import ETH1xIcon from "../../assets/icons/ether@1x.png";
import ETH2xIcon from "../../assets/icons/ether@2x.png";
import BNB1xIcon from "../../assets/icons/bnb@1x.png";
import BNB2xIcon from "../../assets/icons/bnb@2x.png";
import URUS1xIcon from "../../assets/icons/urus-coin@1x.png";
import URUS2xIcon from "../../assets/icons/urus-coin@2x.png";
import { TextRow } from "../../components/common/text/TextRow";
import { ChainId, Colors, DEFAULT_CHAIN_ID, WRAPPED_NATIVE_CURRENCY } from "../../constants";
import { TransactionStatus } from "../../infrastructure";
import { addLiquidity } from "../../infrastructure/addLiquidity";
import { useContracts, useEstimatedPoolShare, useEthers } from "../../hooks";
import { useBlockTimestamp } from "../../hooks/useBlockTimestamp";
import { useLPAmountMinted } from "../../hooks/useLPAmountMinted";
import { toPercent } from "../../helpers";
import { FailMessage } from "../../components/common";
import { Transaction } from "ethers";
import { TransactionInfo } from "../../components/common/TransactionInfo";

interface ConfirmationModalProps {
  isVisible: boolean;
  onClose: () => void;
  ethAmount: string;
  urusAmount: string;
  ethPerUrus: string;
  urusPerEth: string;
}

export const ConfirmationModal = ({
  isVisible,
  onClose,
  ethAmount,
  urusAmount,
  ethPerUrus,
  urusPerEth,
}: ConfirmationModalProps) => {
  const { account, chainId } = useEthers();
  const contracts = useContracts();
  const blockTimestamp = useBlockTimestamp();
  const [transactionStatus, setTransactionStatus] = useState(
    TransactionStatus.New
  );
  const lpMinted = useLPAmountMinted(urusAmount, ethAmount);
  const estimatedShare = useEstimatedPoolShare(urusAmount, ethAmount);
  const [transaction, setTransaction] = useState<Transaction>();
  if (!isVisible) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <Modal>
        <ModalTop>
          <Row>
            <ModalTitle>You will receive</ModalTitle>
            <CloseButton onClick={onClose}>
              <CloseIcon />
            </CloseButton>
          </Row>
          <AmountWrapper>
            <Amount>{lpMinted ? lpMinted.format() : "?"}</Amount>
            <img src={URUS1xIcon} alt="URUS icon" srcSet={URUS2xIcon} />
            {chainId === ChainId.Binance ? (
              <img src={BNB1xIcon} alt="BNB icon" srcSet={BNB2xIcon} />
            ) : (
              <img src={ETH1xIcon} alt="ETH icon" srcSet={ETH2xIcon}  />
            )}
          </AmountWrapper>
          <AmountLabel>URUS/{WRAPPED_NATIVE_CURRENCY[chainId ?? DEFAULT_CHAIN_ID].symbol} Pool Tokens</AmountLabel>
          <Hint>
            Output is estimated. If the price changes by more than 0.5% your
            transaction will revert.
          </Hint>
        </ModalTop>
        <ModalBottom>
          <TextRow
            label="URUS Deposited"
            value={
              <ValueRow>
                <img src={URUS1xIcon} alt="URUS icon" srcSet={URUS2xIcon} />
                <span>{urusAmount}</span>
              </ValueRow>
            }
          />
          <TextRow
            label={`${WRAPPED_NATIVE_CURRENCY[chainId ?? DEFAULT_CHAIN_ID].symbol} deposited`}
            value={
              <ValueRow>
                {chainId === ChainId.Binance ? (
                  <img src={BNB1xIcon} alt="BNB icon" srcSet={BNB2xIcon} />
                ) : (
                  <img src={ETH1xIcon} alt="ETH icon" srcSet={ETH2xIcon}  />
                )}
                <span>{ethAmount}</span>
              </ValueRow>
            }
          />
          <StyledTextRow
            label="Rates"
            value={
              <>
                <>1 {WRAPPED_NATIVE_CURRENCY[chainId ?? DEFAULT_CHAIN_ID].symbol} = {ethPerUrus} URUS</>
                <>1 URUS = {urusPerEth} {WRAPPED_NATIVE_CURRENCY[chainId ?? DEFAULT_CHAIN_ID].symbol}</>
              </>
            }
          />
          <TextRow
            label="Share of Pool"
            value={estimatedShare ? toPercent(estimatedShare) : "?%"}
          />
          <ConfirmButton
            isFullWidth
            onClick={
              [TransactionStatus.Success, TransactionStatus.Fail].includes(
                transactionStatus
              )
                ? onClose
                : () => {
                    if (blockTimestamp === undefined) {
                      throw new Error("Block timestamp not yet known.");
                    }
                    if (!account) {
                      throw new Error("Account not connected.");
                    }
                    const deadline = blockTimestamp + 1000;
                    addLiquidity(
                      contracts.uniswapRouter,
                      contracts.auroxToken,
                      ethAmount,
                      urusAmount,
                      account.address,
                      deadline,
                      setTransactionStatus,
                      setTransaction
                    );
                  }
            }
            isLoading={transactionStatus === TransactionStatus.Mining}
          >
            {[TransactionStatus.Success, TransactionStatus.Fail].includes(
              transactionStatus
            )
              ? "Done"
              : "Confirm Transaction"}
          </ConfirmButton>
          {transactionStatus === TransactionStatus.Fail && <FailMessage />}
          {!!transaction && transactionStatus === TransactionStatus.Mining && (
            <TransactionInfo transaction={transaction} />
          )}
        </ModalBottom>
      </Modal>
    </ModalOverlay>
  );
};

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 400px;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
`;

const ModalTop = styled.div`
  padding: 24px 16px 16px;
  background: ${Colors.BackgroundPrimary};
`;

const ModalBottom = styled.div`
  padding: 24px 16px;
  background: #1c273f;
`;

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  padding: 0;
  border: none;
  background: none;
`;

const AmountWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 16px;

  img {
    width: 20px;
    height: 20px;
  }

  img + img {
    margin-left: 2px;
  }
`;

const Amount = styled.p`
  margin-right: 8px;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  display: flex;
  align-items: center;
  color: ${Colors.White};
`;

const AmountLabel = styled.p`
  margin: 16px 0 24px;
  font-size: 20px;
  line-height: 23px;
  color: ${Colors.White};
`;

const Hint = styled.p`
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: ${Colors.PrimaryTextColor};
`;

const ConfirmButton = styled(ButtonPrimary)`
  margin-top: 32px;
`;

const ValueRow = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;

const StyledTextRow = styled(TextRow)`
  align-items: flex-start;

  span + span {
    display: block;
    margin-top: 16px;
  }
`;
