import styled from "styled-components";
import React, { FunctionComponent } from "react";
import { offsetValue } from "./offsetValue";
import { Section } from "./Section";
import { Colors } from "../../constants";

type Props = {
  set: (s: string) => void;
  value: string;
};

export const CustomSpinnerInput: FunctionComponent<Props> = ({
  set,
  value,
}) => (
  <Wrapper>
    <Button type="button" onClick={() => set(offsetValue(value, -1))}>
      -
    </Button>
    <SpinnerlessInput
      type="number"
      min="0"
      max="99"
      value={value}
      onChange={(e) => set(e.target.value)}
    />
    <Button type="button" onClick={() => set(offsetValue(value, 1))}>
      +
    </Button>
  </Wrapper>
);

const Wrapper = styled(Section)`
  display: flex;
  align-items: center;
  max-width: 95px;
  margin: 24px 0 16px;
`;

const SpinnerlessInput = styled.input`
  margin: 0 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: ${Colors.White};
  -moz-appearance: textfield;
  background: none;
  border: none;
  outline: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Button = styled.button`
  width: 12px;
  font-size: 20px;
  line-height: 23px;
  color: ${Colors.PrimaryTextColor};
  padding: 0;
  background: none;
  border: none;
  outline: none;
`;
