import { BigNumber } from "ethers";
import { Interface } from "ethers/lib/utils";
import { useMemo } from "react";
import Provider from "../abi/Provider.json";
import { DEFAULT_CHAIN_ID, PROVIDER_ADDRESS } from "../constants";
import { useChainCall } from "./useChainCalls";
import { useCurrentEpoch } from "./useCurrentEpoch";
import { useEthers } from "./useEthers";

const providerInterface = new Interface(Provider.abi);
const functionName = "_getSecondsToEpochEnd";

export function useSecondsToEpochEnd() {
  const { chainId = DEFAULT_CHAIN_ID } = useEthers();
  const currentEpoch = useCurrentEpoch();
  const data = useChainCall(
    !!currentEpoch && {
      address: PROVIDER_ADDRESS[chainId],
      data: providerInterface.encodeFunctionData(functionName, [currentEpoch]),
    }
  );
  return useMemo(() => (data ? BigNumber.from(data) : undefined), [data]);
}
