import { BigNumber } from "ethers";
import { Interface } from "ethers/lib/utils";
import { useMemo } from "react";
import { useEthers } from ".";
import { DEFAULT_CHAIN_ID } from "../constants";
import { CurrencyValue, Token } from "../model";
import { useChainCall } from "./useChainCalls";
import ierc20 from "../abi/erc20.abi.json";

const tokenInterface = new Interface(ierc20);

export function useTokenSupply(token: Token) {
  const { chainId = DEFAULT_CHAIN_ID } = useEthers();
  const data = useChainCall(
    token.chainId === chainId && {
      address: token.address,
      data: tokenInterface.encodeFunctionData("totalSupply", []),
    }
  );
  return useMemo(
    () =>
      data !== undefined
        ? new CurrencyValue(token, BigNumber.from(data))
        : undefined,
    [data]
  );
}
