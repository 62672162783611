import Web3Modal from "web3modal";

import {
  ChainId,
  ChainName,
  CHAIN_NAMES,
  CHAIN_NAME_TO_CHAIN_ID_MAPPING,
  DEFAULT_CHAIN_NAME,
} from "../../constants";

import { PROVIDER_OPTIONS_BY_CHAIN } from "../../constants/provider";

export function getWeb3Network() {
  const cachedNetwork = localStorage.getItem("web3network");

  if (!cachedNetwork || !CHAIN_NAMES.includes(cachedNetwork as ChainName)) {
    return DEFAULT_CHAIN_NAME;
  }

  return cachedNetwork as ChainName;
}

let cached: {
  chainName: ChainName;
  modal: Web3Modal;
} | null = null;

export function getWeb3Modal(chainName?: ChainName) {
  if (cached && cached.chainName === chainName) {
    return cached.modal;
  }

  if (chainName) {
    document.getElementById("WEB3_CONNECT_MODAL_ID")?.remove();

    const options = {
      network: chainName,
      cacheProvider: false,
      disableInjectedProvider: false,
      providerOptions: PROVIDER_OPTIONS_BY_CHAIN[CHAIN_NAME_TO_CHAIN_ID_MAPPING[chainName] as ChainId],
      lightboxOpacity: 0.75,
    };

    const modal = new Web3Modal(options);

    document.getElementById("WEB3_CONNECT_MODAL_ID")?.children?.[0]?.setAttribute("style", "z-index:2000");

    cached = { chainName, modal };

    return cached.modal;
  }
}

export async function switchChain(provider: any, chainName: ChainName) {
  const { isMetaMask, request } = provider;

  if (isMetaMask && request) {
    if (chainName === "binance") {
      try {
        await request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${ChainId.Binance.toString(16)}` }],
        });
      } catch (switchError) {
        if ((switchError as any).code === 4902) {
          try {
            await request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: `0x${ChainId.Binance.toString(16)}`,
                  chainName: "Binance Smart Chain Mainnet",
                  nativeCurrency: {
                    name: "Binance Coin",
                    symbol: "BNB",
                    decimals: 18,
                  },
                  rpcUrls: ["https://bsc-dataseed1.binance.org", "https://bsc-dataseed1.ninicoin.io", "https://bsc-dataseed1.defibit.io"],
                  blockExplorerUrls: ["https://bscscan.com/"],
                }
              ],
            });
          } catch (addError) {
            return null;
          }
        } else {
          return null;
        }
      }
    } else if (chainName === "mainnet") {
      try {
        await request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${Number(ChainId.Mainnet).toString(16)}` }],
        });
      } catch (switchError) {
        return null;
      }
    }
  }

  return provider;
}
