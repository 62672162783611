import { BigNumber, utils } from "ethers";
import { DECIMAL_BASE } from "../../constants";
import { TokenPrice } from "../../model";
import { LiquidityValues } from "./liquidityReducer";

type currencySymbol = "eth" | "urus";

export function calculateValue(
  value: BigNumber,
  price: TokenPrice | undefined,
  currency: currencySymbol
): string {
  if (price === undefined) {
    return "0";
  }
  const priceString =
    currency === "urus"
      ? utils.parseEther(price.reverse().toString())
      : utils.parseEther(price.toString());
  const result = value.mul(priceString).div(DECIMAL_BASE.pow(18));
  return result.eq(0) ? "0" : utils.formatEther(result);
}

export function calculateEthValue(
  value: BigNumber,
  price: TokenPrice | undefined
): string {
  return calculateValue(value, price, "eth");
}

export function calculateUrusValue(
  value: BigNumber,
  price: TokenPrice | undefined
): string {
  return calculateValue(value, price, "urus");
}

export function displayExchangeRate(
  price: TokenPrice,
  currency: currencySymbol
) {
  return currency === "urus"
    ? price.reverse().toFormattedString(10)
    : price.toFormattedString(10);
}

export function areValuesZero(values: LiquidityValues) {
  return (
    utils.parseEther(values.ethAmount).lte(0) ||
    utils.parseEther(values.urusAmount).lte(0)
  );
}
