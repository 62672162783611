import React, { ReactNode } from "react";
import styled from "styled-components";

interface WrapperProps {
  children: ReactNode;
}

export const Wrapper = ({ children }: WrapperProps) => (
  <StyledWrapper>
    <Container>{children}</Container>
  </StyledWrapper>
);

const StyledWrapper = styled.div`
  position: relative;
  min-height: 100vh;
`;

const Container = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 30px;
`;
