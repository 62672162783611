import React, { ReactNode } from "react";
import styled from "styled-components";
import { Colors } from "../../../constants";
import { Row } from "../Row";

interface TextRowProps {
  label: string;
  value: ReactNode;
  className?: string;
}

export const TextRow = ({ label, value, className }: TextRowProps) => (
  <Row className={className}>
    <Label>{label}</Label>
    <Value>{value}</Value>
  </Row>
);

const Label = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.GrayAlt};
`;

const Value = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: ${Colors.SecondaryTextColor};
`;
