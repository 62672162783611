import React, { FunctionComponent } from "react";
import styled from "styled-components";

type ButtonProps = {
  active: boolean;
  onClick: () => void;
};

export const Button: FunctionComponent<ButtonProps> = ({
  active,
  children,
  onClick,
}) => (
  <ActualButton active={active} onClick={onClick}>
    {children}
  </ActualButton>
);

const ActualButton = styled.div<ButtonProps>`
  background: ${(props) => (props.active ? "white" : "grey")};
`;
