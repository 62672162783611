import React from "react";

export function SettingsIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.99984" cy="3.33333" r="1.33333" fill="#3F495D" />
      <circle cx="7.99984" cy="8.00008" r="1.33333" fill="#3F495D" />
      <ellipse
        cx="7.99984"
        cy="12.6666"
        rx="1.33333"
        ry="1.33333"
        fill="#3F495D"
      />
    </svg>
  );
}
