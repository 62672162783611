import { UnsupportedChainIdError } from "@web3-react/core";
import React, { useState } from "react";
import styled from "styled-components";

import { Colors } from "../../constants";
import { useEthers } from "../../hooks";
import { ButtonPrimary } from "../common/buttons/ButtonPrimary";

import { Account } from "./Account";
import NetworkSelector from "./NetworkSelector";

export function Connection() {
  const { connectWallet, disconnectWallet, account, error } = useEthers();

  if (error instanceof UnsupportedChainIdError) {
    return <NetworkError>Unsupported network</NetworkError>;
  }

  if (account?.address) {
    return (
      <>
        <Account address={account.address} />
        <Disconnect onClick={disconnectWallet}>Disconnect</Disconnect>
      </>
    );
  }

  return (
    <>
      <NetworkSelector />
      <Connect onClick={connectWallet}>Connect to a wallet</Connect>
    </>
  );
}

const Connect = styled(ButtonPrimary)`
  height: 36px;
  padding: 6px 18px;
`;

const Disconnect = styled(ButtonPrimary)`
  height: 36px;
  padding: 6px 18px;
`;

const NetworkError = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  padding: 6px 18px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  background-color: ${Colors.Red};
  border-radius: 8px;
`;
