import { Interface } from "ethers/lib/utils";
import { useEthers } from ".";
import {
  DEFAULT_CHAIN_ID,
  PROVIDER_ADDRESS,
  URUS_CONTRACT,
} from "../constants";
import { useChainCall } from "./useChainCalls";
import { useCurrentEpoch } from "./useCurrentEpoch";
import Provider from "../abi/Provider.json";
import { useMemo } from "react";
import { CurrencyValue } from "../model";
import { BigNumber } from "ethers";

const providerInterface = new Interface(Provider.abi);

export function usePoolRate() {
  const { chainId = DEFAULT_CHAIN_ID } = useEthers();
  const epoch = useCurrentEpoch();
  const data = useChainCall(
    !!epoch && {
      address: PROVIDER_ADDRESS[chainId],
      data: providerInterface.encodeFunctionData("returnTotalRewardForEpoch", [
        epoch,
      ]),
    }
  );
  return useMemo(
    () =>
      data &&
      new CurrencyValue(URUS_CONTRACT[chainId], BigNumber.from(data).div(2)),
    [data]
  );
}
