import { useEthers } from "./useEthers";
import { Currency, CurrencyValue, Token } from "../model";
import { DEFAULT_CHAIN_ID, URUS_LP_TOKEN } from "../constants";
import { useChainCall } from "./useChainCalls";
import { Interface } from "@ethersproject/abi";
import { BigNumber } from "@ethersproject/bignumber";
import { useMemo } from "react";
import { useContracts } from ".";

export default function useMigrationContractBalance() {
  const { chainId = DEFAULT_CHAIN_ID, account } = useEthers();
  const { providerMigration } = useContracts();

  // return undefined as any;
  try {
    const data = useChainCall(
      !!account && {
        address: providerMigration.address,
        data: providerMigration.interface.encodeFunctionData("balances", [
          account.address,
        ]),
      }
    );

    return useMemo(
      () =>
        data !== undefined
          ? new CurrencyValue(URUS_LP_TOKEN[chainId], BigNumber.from(data))
          : undefined,
      [data]
    );
  } catch (e) {
    return new CurrencyValue(URUS_LP_TOKEN[chainId], BigNumber.from("0"));
  }
}
