import styled from "styled-components";
import { Colors } from "../../constants";

export const Section = styled.div`
  padding: 16px;
  border: 1px solid ${Colors.BorderSecondary};
  border-radius: 12px;
`;

export const SectionLabel = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.PrimaryTextColor};
`;

export const SectionLabelDanger = styled(SectionLabel)`
  color: ${Colors.Danger};
`;

export const SectionRow = styled.div`
  display: flex;
  align-items: center;
`;
