import { BigNumber, utils } from "ethers";
import { useEthers } from ".";
import {
  DEFAULT_CHAIN_ID,
  URUS_CONTRACT,
  URUS_LP_TOKEN,
  WRAPPED_NATIVE_CURRENCY,
} from "../constants";
import { sqrt } from "../helpers";
import { CurrencyValue } from "../model";
import { useLPTokenSupply } from "./useLPTokenSupply";
import { useReserves } from "./useReserves";

const MIN_LIQUIDITY = BigNumber.from(1000);

export function useLPAmountMinted(amountUrus: string, amountEth: string) {
  const reserves = useReserves();
  const totalSupply = useLPTokenSupply();
  const { chainId = DEFAULT_CHAIN_ID } = useEthers();
  const addressUrus = URUS_CONTRACT[chainId].address;
  const addressWeth = WRAPPED_NATIVE_CURRENCY[chainId].address;
  const amounts = BigNumber.from(addressUrus).lt(BigNumber.from(addressWeth))
    ? [utils.parseEther(amountUrus), utils.parseEther(amountEth)]
    : [utils.parseEther(amountEth), utils.parseEther(amountUrus)];
  if (reserves && totalSupply) {
    let lpMinted: BigNumber;
    if (totalSupply.value.eq(0)) {
      lpMinted = BigNumber.from(
        sqrt(amounts[0].mul(amounts[1]).toNumber())
      ).sub(MIN_LIQUIDITY);
    } else {
      const amount0 = amounts[0].mul(totalSupply.value).div(reserves[0]);
      const amount1 = amounts[1].mul(totalSupply.value).div(reserves[1]);
      lpMinted = amount0.lte(amount1) ? amount0 : amount1;
    }
    if (lpMinted.lte(0)) {
      throw new Error("Insufficient input amount");
    }
    return new CurrencyValue(URUS_LP_TOKEN[chainId], lpMinted);
  }
  return undefined;
}
