import React, { useEffect, useReducer, ReactNode } from "react";
import { BlockNumberContext } from "./context";
import { blockNumberReducer } from "./reducer";
import { useEthers, useDebounce } from "../../hooks";

interface Props {
  children: ReactNode;
}

export function BlockNumberProvider({ children }: Props) {
  const { ethersProvider, chainId } = useEthers();
  const [state, dispatch] = useReducer(blockNumberReducer, {});

  useEffect(() => {
    if (ethersProvider && chainId !== undefined) {
      const update = (blockNumber: number) =>
        dispatch({ chainId, blockNumber });
      ethersProvider.on("block", update);
      return () => {
        ethersProvider.off("block", update);
      };
    }
  }, [ethersProvider, chainId]);

  const debouncedState = useDebounce(state, 100);
  const blockNumber =
    chainId !== undefined ? debouncedState[chainId] : undefined;

  return (
    <BlockNumberContext.Provider value={blockNumber} children={children} />
  );
}
