import { constants, utils } from "ethers";
import { DEFAULT_CHAIN_ID, NUMBER_REGEX } from "../../constants";
import { stripLeadingZeroes, truncateDecimals } from "../../hooks";
import { ONE, TokenPrice } from "../../model";
import { calculateEthValue, calculateUrusValue } from "./helpers";

export type LiquidityValues = {
  ethAmount: string;
  urusAmount: string;
  price: TokenPrice;
};
export const initialState: LiquidityValues = {
  ethAmount: "0",
  urusAmount: "0",
  price: TokenPrice.createFrom(
    constants.AddressZero,
    DEFAULT_CHAIN_ID,
    ONE,
    ONE,
    18
  ),
};
export type LiquidityModalAction =
  | { type: "amountChanged"; fieldChanged: "eth" | "urus"; value: string }
  | { type: "priceChanged"; value: TokenPrice };

export function liquidityReducer(
  state: LiquidityValues,
  action: LiquidityModalAction
) {
  if (action.type == "priceChanged") {
    return {
      ethAmount: state.ethAmount,
      urusAmount: state.urusAmount,
      price: action.value,
    };
  }
  if (NUMBER_REGEX.test(action.value)) {
    const value = format(action.value);
    const valueNumber = utils.parseEther(value);
    if (action.fieldChanged == "eth") {
      return {
        price: state.price,
        ethAmount: value,
        urusAmount: calculateUrusValue(valueNumber, state.price),
      };
    }
    return {
      price: state.price,
      urusAmount: value,
      ethAmount: calculateEthValue(valueNumber, state.price),
    };
  }

  return state;
}

function format(value: string) {
  return value.length > 0
    ? truncateDecimals(stripLeadingZeroes(value), 18)
    : "0";
}
