import React, { ReactNode } from "react";
import styled from "styled-components";
import { TopBar } from "../top";
import { SideBar } from "../sidebar";
import { BlockNumber } from "./BlockNumber";
import { Version } from "./Version";
import { Wrapper } from "./Wrapper";

interface Props {
  children: ReactNode;
}

export function Page({ children }: Props) {
  return (
    <Wrapper>
      <TopBar />
      <PageLayout>
        <SideBar />
        {children}
      </PageLayout>
      {/* <Version /> */}
      <BlockNumber />
    </Wrapper>
  );
}

const PageLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 82px 0;
`;
