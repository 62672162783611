import styled from "styled-components";
import { Colors } from "../../../constants";

export const PageTitle = styled.h1`
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${Colors.White};
`;
