import { BigNumber } from "ethers";
import { TransactionStatus } from "../../infrastructure";
import { CurrencyValue } from "../../model";
import { isSufficientBalance } from "../../helpers";
import { formatRemainingTime } from "../../helpers";

export function isStakingEnabled(
  amount: CurrencyValue,
  stakeTime: string,
  status: TransactionStatus,
  urusBalance: CurrencyValue | undefined
) {
  return (
    amount.value.gt(0) &&
    Number.parseFloat(stakeTime) > 0 &&
    status !== TransactionStatus.Mining &&
    isSufficientBalance(urusBalance, amount)
  );
}

export function isStakeReady(endTime: BigNumber, now = Date.now()) {
  return now > toMilliseconds(endTime);
}

export function toMilliseconds(time: BigNumber) {
  return time.mul(1000).toNumber();
}

export function nowInSeconds(now = Date.now()) {
  return Math.floor(now / 1000);
}

export function remainingTime(endTime: BigNumber, now = Date.now()) {
  const remaining = remainingSeconds(endTime, now);
  if (remaining <= 0) {
    return "Ready to claim";
  }
  return formatRemainingTime(remaining);
}

export function remainingSeconds(endTime: BigNumber, now = Date.now()) {
  return endTime.sub(nowInSeconds(now)).toNumber();
}
