import React, { useState } from "react";
import styled from "styled-components";
import { ButtonPrimary } from "../common/buttons/ButtonPrimary";
import { Modal } from "../common/modal/Modal";
import { useContracts } from "../../hooks";
import { TransactionStatus } from "../../infrastructure";
import { claimLiquidityReward } from "../../infrastructure/claimLiquidityReward";
import { TextRow } from "../common/text/TextRow";
import { FailMessage } from "../common";

interface ClaimLiquidityRewardModalProps {
  isVisible: boolean;
  onClose: () => void;
  claimableReward: string;
}

export const ClaimLiquidityRewardModal = ({
  isVisible,
  onClose,
  claimableReward,
}: ClaimLiquidityRewardModalProps) => {
  const { provider } = useContracts();
  const [status, setStatus] = useState(TransactionStatus.New);

  const isMined = status === TransactionStatus.Success;
  const isMining = status === TransactionStatus.Mining;

  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      title="Claim Liquidity Reward"
    >
      <TextRow label="Pool reward" value={<b>{claimableReward} URUS</b>} />
      <ClaimButton
        isFullWidth
        disabled={isMined}
        isLoading={isMining}
        onClick={() => claimLiquidityReward(provider, setStatus)}
      >
        {isMined ? "Done!" : isMining ? "Claiming..." : "Claim"}
      </ClaimButton>
      {status === TransactionStatus.Fail && <FailMessage />}
    </Modal>
  );
};

const ClaimButton = styled(ButtonPrimary)`
  margin-top: 41px;
`;
