import { Contract } from "ethers";
import AuroxToken from "../abi/AuroxToken.json";
import StakingMaster from "../abi/StakingMaster.json";
import Provider from "../abi/Provider.json";
import erc20abi from "../abi/erc20.abi.json";
import providerMigrationABI from "../abi/ProviderMigration.abi.json";
import IUniswapV2Router02 from "@uniswap/v2-periphery/build/IUniswapV2Router02.json";
import {
  DEFAULT_CHAIN_ID,
  PROVIDER_ADDRESS,
  STAKING_MASTER_ADDRESS,
  URUS_CONTRACT,
  URUS_LP_TOKEN,
  UNISWAPV2ROUTER02,
  PROVIDER_MIGRATION_ADDRESS,
} from "../constants";
import { useEthers } from ".";

export function useContracts() {
  const { chainId = DEFAULT_CHAIN_ID, ethersProvider } = useEthers();
  const signer = ethersProvider?.getSigner();
  // TODO contracts here
  return {
    auroxToken: new Contract(
      URUS_CONTRACT[chainId].address,
      AuroxToken.abi,
      signer
    ),
    lpToken: new Contract(URUS_LP_TOKEN[chainId].address, erc20abi, signer),
    stakingMaster: new Contract(
      STAKING_MASTER_ADDRESS[chainId],
      StakingMaster.abi,
      signer
    ),
    provider: new Contract(PROVIDER_ADDRESS[chainId], Provider.abi, signer),
    uniswapRouter: new Contract(
      UNISWAPV2ROUTER02[chainId],
      IUniswapV2Router02.abi,
      signer
    ),
    providerMigration: new Contract(
      PROVIDER_MIGRATION_ADDRESS[chainId],
      providerMigrationABI.abi,
      signer
    ),
  };
}
