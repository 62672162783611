import { useEffect, useState } from "react";
import { DEFAULT_CHAIN_ID, URUS_CONTRACT } from "../constants";
import { TokenPrice } from "../model";
import { useEthers } from "./useEthers";
import { useReserves } from "./useReserves";

export function useTokenPrice() {
  const { chainId = DEFAULT_CHAIN_ID } = useEthers();

  const [price, setPrice] = useState<TokenPrice>();
  const reserves = useReserves();

  useEffect(() => {
    if (reserves !== undefined) {
      const [reserve0, reserve1] = reserves;
      if (reserve0 && reserve1) {
        setPrice(
          TokenPrice.fromReserves(
            URUS_CONTRACT[chainId].address,
            chainId,
            reserve0,
            reserve1,
            18
          )
        );
      }
    }
  }, [reserves]);

  return price;
}
