import React from "react";
import styled from "styled-components";
import { HelpIcon } from "../icons";

export interface TooltipProps {
  tooltip?: string;
}

export const Tooltip = ({ tooltip }: TooltipProps) => {
  if (tooltip) {
    return (
      <TooltipWrapper>
        <HelpIcon />
        <TooltipBody>{tooltip}</TooltipBody>
      </TooltipWrapper>
    );
  }
  return <TooltipWrapper />;
};

const TooltipBody = styled.div`
  display: none;
  position: absolute;
  bottom: -17px;
  right: -10px;
  transform: translate(0, 100%);
  width: 240px;
  padding: 16px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  background: #66708c;
  border-radius: 8px;
  z-index: 2;

  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 12px;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #66708c;
  }
`;

const TooltipWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
  background: none;
  border: none;
  outline: none;

  &:hover ${TooltipBody} {
    display: inline-block;
  }
`;
