import React from "react";
import styled from "styled-components";
import { Colors, LIQUIDITY_INFO, DEFAULT_CHAIN_ID } from "../../constants";
import { PageTitle } from "../../components/common/text/PageTitle";
import { PageSubtitle } from "../../components/common/text/PageSubtitle";
import { DepositTokens } from "./DepositTokens";
import { AddLiquidityTokens } from "./AddLiquidityTokens";
import { useEthers } from "../../hooks";
import { useSecondsToEpochEnd } from "../../hooks/useSecondsToEpochEnd";
import { formatRemainingTime } from "../../helpers";

export function LiquidityMining() {
  const { chainId } = useEthers();
  const seconds = useSecondsToEpochEnd();

  return (
    <View>
      <PageTitle>Aurox Liquidity Mining</PageTitle>
      <PageSubtitle>
        Deposit your Liquidity Provider tokens from {LIQUIDITY_INFO[chainId ?? DEFAULT_CHAIN_ID].exchange} to receive URUS
        token rewards.
      </PageSubtitle>
      <Pools>
        <PoolsHeader>
          <PoolsTitle>Participating Pools</PoolsTitle>
          <RewardsTimer>
            Next epoch starts in{" "}
            {seconds ? formatRemainingTime(seconds.toNumber()) : "?"}
          </RewardsTimer>
        </PoolsHeader>
      </Pools>
      <DepositTokens />
      <AddLiquidityTokens />
    </View>
  );
}

const View = styled.div`
  margin-left: 30px;
`;

const Pools = styled.div`
  margin-top: 65px;
`;

const PoolsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const PoolsTitle = styled.h3`
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: ${Colors.White};
`;

const RewardsTimer = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: #6fcf97;
`;
