import { BigNumber, BigNumberish } from "ethers";

const ONE = BigNumber.from(1);
const TWO = BigNumber.from(2);

export function sqrt(value: BigNumberish) {
  const x = BigNumber.from(value);
  if (x.lt("0")) {
    throw new Error("Square root of a negative number");
  }
  let z = x.add(ONE).div(TWO);
  let y = x;
  while (z.sub(y).isNegative()) {
    y = z;
    z = x.div(z).add(z).div(TWO);
  }
  return y;
}
