const yearSeconds = 365 * 24 * 60 * 60;
const daySeconds = 24 * 60 * 60;
const hourSeconds = 60 * 60;
const minuteSeconds = 60;

export function formatRemainingTime(seconds: number) {
  const years = Math.floor(seconds / yearSeconds);
  const days = Math.floor((seconds - years * yearSeconds) / daySeconds);
  const hours = Math.floor(
    (seconds - years * yearSeconds - days * daySeconds) / hourSeconds
  );
  const minutes = Math.floor(
    (seconds - years * yearSeconds - days * daySeconds - hours * hourSeconds) /
      minuteSeconds
  );
  const time = `${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }`;
  return (years ? `${years}y ` : "") + (days ? `${days}d ` : "") + time;
}
