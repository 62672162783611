import { Interface } from "@ethersproject/abi";
import { useMemo } from "react";
import StakingMaster from "../abi/StakingMaster.json";
import { DEFAULT_CHAIN_ID, STAKING_MASTER_ADDRESS } from "../constants";
import { decodeStake } from "../model/Stake";
import { useChainCall } from "./useChainCalls";
import { useEthers } from "./useEthers";

const stakesInterface = new Interface(StakingMaster.abi);
const functionName = "returnStakeState";

export function useStakeState(stakeAddress: string) {
  const { chainId = DEFAULT_CHAIN_ID } = useEthers();
  const data = useChainCall({
    address: STAKING_MASTER_ADDRESS[chainId],
    data: stakesInterface.encodeFunctionData(functionName, [stakeAddress]),
  });
  return useMemo(() => decodeStake(data, functionName, stakeAddress), [data]);
}
