import React, { useState } from "react";
import styled from "styled-components";
import { FailMessage } from "../../components/common";
import { ButtonPrimary } from "../../components/common/buttons/ButtonPrimary";
import { Modal } from "../../components/common/modal/Modal";
import { TextRow } from "../../components/common/text/TextRow";
import { useContracts, usePoolReward } from "../../hooks";
import { TransactionStatus } from "../../infrastructure";
import { claimStake } from "../../infrastructure/claimStake";
import { Stake } from "../../model/Stake";
import { RewardAmount } from "./RewardAmount";

interface ClaimModalProps {
  isVisible: boolean;
  onClose: () => void;
  stake: Stake;
}

export const ClaimStakeModal = ({
  isVisible,
  onClose,
  stake,
}: ClaimModalProps) => {
  const { stakingMaster } = useContracts();
  const [status, setStatus] = useState(TransactionStatus.New);
  const poolReward = usePoolReward(stake.address);

  const isMined = status === TransactionStatus.Success;
  const isMining = status === TransactionStatus.Mining;

  return (
    <Modal isVisible={isVisible} onClose={onClose} title="Claim">
      <TextRow
        label="Pool reward"
        value={
          <>
            <RewardAmount stake={stake} /> URUS
          </>
        }
      />

      <TextRow
        label="Penalty pool reward"
        value={`${poolReward?.format()} URUS`}
      />

      <ClaimButton
        isFullWidth
        disabled={isMined}
        isLoading={isMining}
        onClick={() => claimStake(stakingMaster, stake.address, setStatus)}
      >
        {isMined ? "Done!" : isMining ? "Claiming..." : "Claim"}
      </ClaimButton>
      {status === TransactionStatus.Fail && <FailMessage />}
    </Modal>
  );
};

const ClaimButton = styled(ButtonPrimary)`
  margin-top: 41px;
`;
